import useGetData from '../../hooks/useGetData'
import { ClientLatestOrderTypes, ClientTypes } from '../../interfaces/client'
import apiService from '../../services/api/apiService'
import RenderIf from '../UI/RenderIf'
import ClientLatestOrdersSkeleton from '../Skeletons/ClientLatestOrdersSkeleton'
import LatestOrders from './LatestOrders'

interface Props {
  client?: ClientTypes
  height?: 'max-h-[calc(100vh-540px)]' | 'h-max'
}

function LatestOrdersContainer({ client, height = 'max-h-[calc(100vh-540px)]' }:Props) {
  const {
    data:
      latestOrders,
    isLoading: isLatestOrdersLoading
  } = useGetData<ClientLatestOrderTypes[]>(
    {
      queryKey: `clientLatestOrders, ${client?.client_number}`,
      queryFn: () => apiService.getClientsLatestOrders(client?.client_number || ''),
      disabled: !client?.client_number
    }
  )

  return (
    <>
      <RenderIf isTrue={isLatestOrdersLoading}>
        <ClientLatestOrdersSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isLatestOrdersLoading}>
        <LatestOrders height={height} latestOrders={latestOrders} client={client} />
      </RenderIf>
    </>
  )
}

export default LatestOrdersContainer
