import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageSection from '../../../../components/UI/PageSection'
import PageTitle from '../../../../components/UI/PageTitle'
import UpdateUserDetailsForm from './components/UpdateUserDetailsForm'

function Settings() {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `Emoria CRM - ${t('settings')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text="settings" />
      <UpdateUserDetailsForm />
      {/* <RenderIf isTrue={authUser?.user.user_type === 'DIRECTOR'}> */}
      {/*  <CronActions /> */}
      {/* </RenderIf> */}
    </PageSection>
  )
}

export default Settings
