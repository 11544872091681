import { useState, useEffect } from 'react'
import { useProductsCart } from '../../../../../context/ProductsCartCtx/ProductsCartProvider'
import { OrderProductTypes } from '../../../../../interfaces/products'
import {
  calculatePrice, calculatePriceSingleViewPage,
  calculateQuantity,
  calculateTaxPrice,
  calculateTaxPriceSingleViewPage
} from '../../../../../helpers/calculate-price'

interface Props {
  orderProducts?: OrderProductTypes[]
  orderTotalPrice?: string
}

function OrderTotalAmount({ orderTotalPrice, orderProducts }:Props) {
  // Display Selected Order Price
  const [orderDetailQuantity, setOrderDetailQuantity] = useState<number | null>(null)
  const [orderDetailPrice, setOrderDetailPrice] = useState<number | null>(null)
  const [orderDetailTaxPrice, setOrderDetailTaxPrice] = useState<number | null>(null)

  // Display New Order Price
  const { totalQuantity, totalPrice, taxPrice } = useProductsCart()

  useEffect(() => {
    if (orderProducts) {
      const getTotalQuantity = calculateQuantity(orderProducts)
      const getTotalPrice = calculatePriceSingleViewPage(orderProducts)
      const getTaxPrice = calculateTaxPriceSingleViewPage(orderProducts)
      setOrderDetailQuantity(getTotalQuantity)
      setOrderDetailPrice(getTotalPrice)
      setOrderDetailTaxPrice(getTaxPrice)
    }
  }, [orderProducts])

  return (
    <div className="w-full lg:w-[calc(50%+10px)] bg-[#FBFBFB] rounded-xl ml-auto mt-[9px] p-7">
      <div className="flex items-center gap-7">
        <p className="text-primary-text-dark text-lg font-semibold pb-3">Σύνολο</p>
        <div className={`text-lg gap-3 flex items-center text-[#959292] justify-between w-full border-b border-solid border-b-[#d4d4d4] pb-3  ${orderProducts ? 'sm:pl-20' : 'sm:pl-5'}`}>
          <p>
            {orderDetailQuantity ?? `${totalQuantity}`}
            {' '}
            τμχ
          </p>
          <div className="flex gap-20">
            <p className={`${orderProducts ? 'sm:-ml-20' : 'sm:ml-28'} `}>
              {orderDetailTaxPrice?.toFixed(2) ?? `${taxPrice.toFixed(2)} `}
              {' '}
              €
            </p>
            <p>
              {orderDetailPrice?.toFixed(2) ?? `${totalPrice.toFixed(2)} `}
              {' '}
              €
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end text-xl mt-[9px] font-semibold">
        <p>
          {/* eslint-disable-next-line no-unsafe-optional-chaining */}
          {orderTotalPrice ? Number(orderTotalPrice).toFixed(2) : `${(taxPrice + totalPrice).toFixed(2)}`}
          €
        </p>
      </div>
    </div>
  )
}

export default OrderTotalAmount
