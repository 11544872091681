const callFrequenciesOptions = [
  { id: 1, label: 'chooseFrequency', value: '' },
  { id: 2, label: '0', value: '0' },
  { id: 3, label: '1', value: '1' },
  { id: 4, label: '2', value: '2' },
  { id: 5, label: '3', value: '3' },
  { id: 6, label: '4', value: '4' },
  { id: 7, label: '5', value: '5' },
  { id: 8, label: '6', value: '6' },
  { id: 9, label: '7', value: '7' },
  { id: 10, label: '8', value: '8' },
  { id: 11, label: '9', value: '9' },
  { id: 12, label: '10', value: '10' },
  { id: 13, label: '11', value: '11' },
  { id: 14, label: '12', value: '12' },
  { id: 15, label: '13', value: '13' },
  { id: 16, label: '14', value: '14' },
  { id: 17, label: '15', value: '15' },
  { id: 18, label: '16', value: '16' },
  { id: 19, label: '17', value: '17' },
  { id: 20, label: '18', value: '18' },
  { id: 21, label: '19', value: '19' },
  { id: 22, label: '20', value: '20' },
  { id: 23, label: '21', value: '21' },
  { id: 24, label: '22', value: '22' },
  { id: 25, label: '23', value: '23' },
  { id: 26, label: '24', value: '24' },
  { id: 27, label: '25', value: '25' },
  { id: 28, label: '26', value: '26' },
  { id: 29, label: '27', value: '27' },
  { id: 30, label: '28', value: '28' },
  { id: 31, label: '29', value: '29' },
  { id: 32, label: '30', value: '30' }
]

export default callFrequenciesOptions
