import ReactQuill, { Quill } from 'react-quill'
import {
  useEffect
} from 'react'
import { useTranslation } from 'react-i18next'
import FormTitle from '../../../../../../Director/components/UI/FormTitle'
import 'react-quill/dist/quill.snow.css'
import { formats, modules } from '../../../../../../../helpers/editorToolbarOptions'
import SearchProduct from '../../../../Orders/components/SearchProduct'
import Dropdown from '../../../../../../../components/Dropdown/Dropdown'
import useToggleMenu from '../../../../../../../hooks/useToggleMenu'
import RenderIf from '../../../../../../../components/UI/RenderIf'
import FormUploadMedia from '../../../../../../../components/Form/FormUploadMedia'
import { NewslettersCtxTypes } from '../../../../../../../context/NewsletterCtx/newslettersCtx.interface'

Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/align'), true);

interface Props {
  formik: any
  newslettersCtx:NewslettersCtxTypes
}

function DesignEmailStep({
  formik,
  newslettersCtx
}:Props) {
  const { t } = useTranslation()
  const { ref, isOpen, setIsOpen } = useToggleMenu()

  useEffect(() => {
    if (newslettersCtx.searchValue.trim().length > 2) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [newslettersCtx.searchValue, setIsOpen])

  const onRemoveMediaHandler = (name: string) => {
    // Remove file from formik state
    const filteredMedia = [...formik.values.files].filter((file) => file.name !== name)
    // Create new file list
    const list = new DataTransfer()
    filteredMedia.forEach((file) => list.items.add(file))
    // Set new file list
    formik.setFieldValue('files', list.files)
  }

  return (
    <form onSubmit={formik.handleSubmit} className="w-full flex flex-col h-full">
      <FormTitle text="designYourEmail" />
      <ReactQuill
        theme="snow"
        value={formik.values.content}
        onChange={(value) => formik.setFieldValue('content', value)}
        formats={formats}
        modules={modules}
      />
      <FormTitle text="insertProducts" />
      <div className="text-sm flex items-center gap-4 flex-wrap w-full text-grey-pale hover:text-btn-sub transition-colors duration-200">
        <SearchProduct
          searchValue={newslettersCtx.searchValue}
          setIsOpen={setIsOpen}
          onSearchHandler={newslettersCtx.onSearchValueChangeHandler}
        />
        <RenderIf isTrue={newslettersCtx.getter.selectedNewsletterProducts.length > 0}>
          <button
            onClick={newslettersCtx.onClearSelectedNewsletterProductsHandler}
            type="button"
          >
            {t('removeAll')}
          </button>
        </RenderIf>
      </div>
      <div className="relative w-full -top-12 lg:w-1/2" ref={ref}>
        <Dropdown isOpen={isOpen} align="left-0 origin-top-left" triangleAlignment="left-[12px]">
          <div className="max-h-[330px] overflow-y-auto pt-2 ">
            <RenderIf isTrue={newslettersCtx.newsletterProducts.length === 0}>
              <p className="w-full px-5 py-2">
                {t('noProductsFound')}
              </p>
            </RenderIf>
            <RenderIf isTrue={newslettersCtx.newsletterProducts.length !== 0}>
              {newslettersCtx.newsletterProducts.map((product) => (
                <button
                  key={product.product_id}
                  type="button"
                  onClick={() => newslettersCtx.onSelectedNewsletterProductsHandler(product)}
                  className="cursor-pointer text-left px-3 border-b border-solid border-b-[#f2f2f2] py-2 last:border-b-0 hover:bg-[rgba(0,0,0,0.02)]"
                >
                  {`${product.product_sku} ${product.product_name}`}
                </button>
              ))}
            </RenderIf>
          </div>
        </Dropdown>
      </div>
      <FormTitle text="emailGreeting" />
      <textarea rows={2} className="outline-none w-full border border-[#ccc] rounded-lg p-2" {...formik.getFieldProps('emailGreeting')} />

      <FormTitle text="attachFiles" />
      <FormUploadMedia
        setMedia={formik.setFieldValue}
        media={formik.values.files}
        onRemoveMediaHandler={onRemoveMediaHandler}
        acceptedFiles="*"
      />
    </form>
  )
}

export default DesignEmailStep
