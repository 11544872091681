import { useTranslation } from 'react-i18next'
import { ChangeEvent, useMemo } from 'react'
import { AiFillCheckCircle, AiFillMinusCircle } from 'react-icons/ai'
import { HiDotsCircleHorizontal } from 'react-icons/hi'
import moment from 'moment'
import Table from '../../../../../components/Table/Table'
import orderStateColors, { ColorState } from '../../../../../helpers/order-state-colors'
import { OrderPaginateTypes, OrderTypes } from '../../../../../interfaces/orders'
import { InfiniteActionPaginateTypes } from '../../../../../interfaces/client'
import { SortingOrderTypes } from '../../../../../interfaces/table'
import sortingHeadersOrders from '../../../../../helpers/sortingHeadersOrders'

interface Props {
  orders: OrderPaginateTypes['results']
  orderPaginate: InfiniteActionPaginateTypes
  searchTextOrder: string
  onSearchOrderHandler: (event: ChangeEvent<HTMLInputElement>) => void
  isHidden: boolean
  onSortingOrderHandler: () => void
  onSortTextHandler: (sortText: string) => void
  sortText: string
  sortingOrder: SortingOrderTypes
}

interface OriginalTypes {
  cell: { row: { original: OrderTypes } }
}

function OrdersTable({
  orders = [],
  orderPaginate,
  searchTextOrder,
  onSearchOrderHandler,
  isHidden,
  onSortingOrderHandler,
  onSortTextHandler,
  sortText,
  sortingOrder
}:Props) {
  const { t } = useTranslation()

  const columns = useMemo(() => [
    {
      Header: t('orderNum'),
      accessor: 'order_number',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p>
          #
          {value}
        </p>
      )
    },
    {
      Header: t('documentary'),
      accessor: 'fincode',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p>{value || '-'}</p>
    },
    {
      Header: t('client'),
      accessor: 'client.full_name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="overflow-y-hidden text-ellipsis line-clamp-2 w-[200px]">{value}</p>
    },
    {
      Header: t('city'),
      accessor: 'city',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p>
          {value || '-'}
        </p>
      )
    },
    {
      Header: t('address'),
      accessor: 'address',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="overflow-y-hidden text-ellipsis line-clamp-2 w-[200px]">{value}</p>
    },
    {
      Header: t('orderDate'),
      accessor: 'created_date',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value}>{moment(value).format('YYYY-MM-DD')}</p>
    },
    {
      Header: t('payment'),
      accessor: 'payment_method',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p className="flex items-center gap-1">
          {value === 'notPaid' && <AiFillMinusCircle size={20} color="#E64848" />}
          {value === 'pending' && <HiDotsCircleHorizontal size={21} color="#BEBEBE" />}
          {value === 'paid' && <AiFillCheckCircle size={20} color="#78C896" />}
          {t(value)}
        </p>
      )
    },
    {
      Header: t('orderState'),
      accessor: 'order_status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p className={`w-[160px] py-[4px] rounded-xl text-center ${orderStateColors(value as ColorState)}`}>
          {t(value)}
        </p>
      )
    },
    {
      Header: t('shipment'),
      accessor: 'shipment',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p>
          {value || '-'}
        </p>
      )
    },
    {
      Header: t('saleAgent'),
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell: { row: { original } } }:OriginalTypes) => (
        <p>
          {`${original.client.agent.user.first_name} ${original.client.agent.user.last_name}`}
        </p>
      )
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={orders}
      storageKey="ordersTable"
      apiPagination={orderPaginate}
      searchText={searchTextOrder}
      onSearchHandler={onSearchOrderHandler}
      isHidden={isHidden}
      onSortingOrderHandler={onSortingOrderHandler}
      onSortTextHandler={onSortTextHandler}
      sortText={sortText}
      sortingOrder={sortingOrder}
      sortingHeadersNames={sortingHeadersOrders}
    />
  )
}

export default OrdersTable
