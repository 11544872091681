import { useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ProductTypes } from '../../../../../interfaces/products'
import PageSection from '../../../../../components/UI/PageSection'
import PageTitle from '../../../../../components/UI/PageTitle'
import ProductDetails from '../components/ProductDetails'
import DetailsInfoBtns from '../../../../../components/UI/DetailsInfoBtns'
import PrintButton from '../../../../../components/UI/PrintButton'

function ProductInfo() {
  const params = useParams<{ id: string }>()
  const products = useOutletContext<ProductTypes[]>()
  const product = products?.find((prd) => prd.product_id === params.id)
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `Emoria CRM - ${t('product')}`
  }, [t])

  return (
    <PageSection>
      <div className="flex justify-between">
        <PageTitle text="product" />
        <PrintButton usedInTitle />
      </div>
      <ProductDetails product={product} />
      <DetailsInfoBtns />
    </PageSection>
  )
}

export default ProductInfo
