// eslint-disable-next-line import/prefer-default-export
export const orderOptions = [
  { id: 1, value: '', label: 'choosePaymentMethod' },
  { id: 2, value: '1000', label: 'cash' },
  { id: 3, value: '1001', label: 'cashWithACS' },
  { id: 4, value: '1002', label: 'mixedModeOfPayment' },
  { id: 5, value: '1003', label: 'cashOnDeliveryDeposit' },
  { id: 6, value: '1004', label: 'onCredit' },
  { id: 7, value: '1005', label: 'credit90days' },
  { id: 8, value: '1006', label: 'cashOnDelivery' },
  { id: 9, value: '1007', label: 'cheque' },
  { id: 10, value: '1008', label: 'deposit' },
  { id: 11, value: '1009', label: 'cashOnDeliverCheck' },
  { id: 12, value: '1010', label: 'bankPayment' },
  { id: 13, value: '1011', label: 'bankPrepayment' }
]

export const seriesOptions = [
  { id: 1, value: '', label: 'chooseDocumentary' },
  { id: 2, value: '7023', label: 'receipt' },
  { id: 3, value: '7021', label: 'invoice' }
]

export const shipmentOptions = [
  { id: 1, value: '', label: 'chooseShipmentMethod' },
  { id: 2, value: '101', label: 'pickUpFromSupplier' },
  { id: 3, value: '102', label: 'postOffice' },
  { id: 4, value: '103', label: 'transportationCompany' },
  { id: 5, value: '104', label: 'acs' },
  { id: 6, value: '105', label: 'sameMedium' },
  { id: 7, value: '106', label: 'customer' },
  { id: 8, value: '1000', label: 'unknown' }
]
