import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Table from '../../../../../components/Table/Table'
import { ClientTypes } from '../../../../../interfaces/client'
import routes from '../../../../../constants/routes'
import UserMethods from '../../../../../utils/userMethods'
import TableActionBtns from '../../../../../components/Table/TableActionBtns'
import { useDataProvider } from '../../../../../context/DataProviderCtx/DataProvider'
import ToughClient from './ToughClient'
import sortingHeadersClients from '../../../../../helpers/sortingHeadersClients'

interface Props {
  clients: ClientTypes[]
  isHidden: boolean
}

interface OriginalTypes {
  cell: { row: { original: ClientTypes } }

}

function ClientsTable({ clients, isHidden }:Props) {
  const {
    clientPaginate,
    searchTextClient,
    sortTextClient,
    sortingOrderClient,
    onSearchClientHandler,
    onSortTextClientHandler,
    onSortingOrderClientHandler
  } = useDataProvider()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const columns = useMemo(() => [
    {
      Header: t('vatNumber'),
      accessor: 'vat_number'
    },
    {
      Header: t('irsBranch'),
      accessor: 'irs_branch'
    },
    {
      Header: t('fullName'),
      accessor: 'full_name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p title={value} className="truncate max-w-[180px]">
          {value}
        </p>
      )
    },
    {
      Header: t('category'),
      accessor: 'category'
    },
    {
      Header: t('address'),
      accessor: 'address',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <p
          title={`${original.city}, ${original.address} ${original.area}, ${original.zip}, ${original.country}, ${original.prefecture}`}
          className="flex flex-col line-clamp-3"
        >
          {`${original.city}, ${original.address} ${original.area}, ${original.zip}, ${original.country}, ${original.prefecture}`}
        </p>
      )
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => (
        <p title={value} className="truncate max-w-[180px]">
          {value === 'ΔΕΝ ΕΧΕΙ MAIL' ? value : <p className="font-semibold">{value}</p>}
        </p>
      )
    },
    {
      Header: t('telephone'),
      accessor: 'telephone',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => (
        <p className="whitespace-nowrap">
          {value}
        </p>
      )
    },
    {
      Header: t('agent'),
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <p
          title={`${original.agent.user.first_name} ${original.agent.user.last_name}`}
          className="truncate max-w-[180px]"
        >
          <span>
            {original.agent.user.first_name}
            {' '}
            {original.agent.user.last_name}
          </span>
        </p>
      )

    },
    {
      Header: t('lastContact'),
      accessor: 'last_contact',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value:string }) => <p>{value ? moment(value).format('YYYY-MM-DD') : '-'}</p>
    },
    {
      Header: t('toughClient'),
      accessor: 'tough_client',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <ToughClient clientNumber={original.client_number} isToughClient={original.tough_client} />
      )
    },
    {
      Header: ' ',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => {
        const user = UserMethods.getUser()
        return (
          <TableActionBtns
            onClickHandlerEdit={() => navigate(`${routes.shared.updateClient}/${original.client_number}`)}
            onClickHandlerOrder={() => navigate(`/${user?.user.user_type.toLowerCase()}/orders/${routes.shared.createOrder}/${original.client_number}`)}
          />

        )
      }
    }
  ], [t, navigate]);

  return (
    <Table
      columns={columns}
      data={clients}
      storageKey="clientsTable"
      apiPagination={clientPaginate}
      searchText={searchTextClient}
      onSearchHandler={onSearchClientHandler}
      isHidden={isHidden}
      sortText={sortTextClient}
      sortingOrder={sortingOrderClient}
      onSortTextHandler={onSortTextClientHandler}
      onSortingOrderHandler={onSortingOrderClientHandler}
      sortingHeadersNames={sortingHeadersClients}
    />
  )
}

export default ClientsTable
