import {
  PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip
} from 'recharts'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import useGetData from '../../hooks/useGetData'
import { TotalWorthProductTypeOrdersStatisticsTypes } from '../../interfaces/statistics'
import apiService from '../../services/api/apiService'
import ChartHeaderFilters from './ChartHeaderFilters'
import usePredefinedDateRange, { PredefinedDateRangeTypes } from '../../hooks/usePredefinedDateRange'
import RenderIf from '../UI/RenderIf'
import WidgetTitle from '../UI/WidgetTitle'

interface Props {
  agent?: string
  gridCss?: string
  predefinedDateRange?: PredefinedDateRangeTypes
}

const COLORS = ['#201923',
  '#d8d8d8',
  '#fcff5d',
  '#7dfc00',
  '#0ec434',
  '#228c68',
  '#8ad8e8',
  '#235b54',
  '#29bdab',
  '#3998f5',
  '#37294f',
  '#277da7',
  '#3750db',
  '#f22020',
  '#991919',
  '#ffcba5',
  '#e68f66',
  '#c56133',
  '#96341c',
  '#632819',
  '#ffc413',
  '#f47a22',
  '#2f2aa0',
  '#b732cc',
  '#772b9d',
  '#f07cab',
  '#d30b94',
  '#edeff3',
  '#c3a5b4',
  '#946aa2',
  '#5d4c86'];

function TotalWorthProductTypeChart({ agent, gridCss, predefinedDateRange }: Props) {
  const localPredefinedDateRange = usePredefinedDateRange()
  const { t } = useTranslation()
  const disabled = predefinedDateRange?.predefinedDateRange
    !== predefinedDateRange?.shouldFetchValue
    || !predefinedDateRange?.endDate

  const localDisabled = localPredefinedDateRange.predefinedDateRange
    !== localPredefinedDateRange.shouldFetchValue
    || !localPredefinedDateRange.endDate

  const { data } = useGetData<TotalWorthProductTypeOrdersStatisticsTypes[]>({
    queryKey: `worthOfOrdersByProductType, ${predefinedDateRange?.predefinedDateRange ?? localPredefinedDateRange.predefinedDateRange} ${agent}`,
    queryFn: () => apiService.getTotalWorthProductTypeOrdersStatistics({
      from: moment(predefinedDateRange?.startDate ?? localPredefinedDateRange.startDate).format('YYYY-MM-DD'),
      to: moment(predefinedDateRange?.endDate ?? localPredefinedDateRange.endDate).format('YYYY-MM-DD'),
      agent
    }),
    disabled: predefinedDateRange?.predefinedDateRange ? disabled : localDisabled
  })

  const dataArray = data?.map((object) => ({
    name: object?.orderproduct__product__product_category,
    value: object?.sum
  }))

  const print = 'print:w-[800px] print:flex-row print:justify-center'
  return (
    <div className={`${gridCss} bg-[#FBFBFB] rounded-[10px] p-[10px] md:py-5 md:px-8 h-full `}>
      <RenderIf isTrue={!!predefinedDateRange}>
        <WidgetTitle text="valueOfOrdersPerKind" fontWeight="font-semibold" />
      </RenderIf>
      <RenderIf isTrue={!predefinedDateRange}>
        <ChartHeaderFilters
          text="valueOfOrdersPerKind"
          predefinedDateRange={localPredefinedDateRange.predefinedDateRange}
          endDate={localPredefinedDateRange.endDate}
          startDate={localPredefinedDateRange.startDate}
          setPredefinedDateRange={localPredefinedDateRange.setPredefinedDateRange}
          setDateRange={localPredefinedDateRange.setDateRange}
        />
      </RenderIf>

      <div className={`w-full h-full flex flex-col md:flex-row items-center md:justify-center ${print}`}>
        <ResponsiveContainer width="99%" height="90%" minHeight={400}>
          <PieChart>
            <Pie
              data={dataArray ?? []}
              innerRadius={80}
              outerRadius={130}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {dataArray?.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip
              formatter={(value, name) => {
                if (name === 'none') {
                  return `${t('notAvailable')}: ${value}€`
                }
                if (!name) {
                  return `${t('withoutCategory')}: ${value}€`
                }

                return `${name}: ${value}€`.replace(`${name}:`?.toString(), '')
              }}
            />
            <Legend
              align="left"
              verticalAlign="bottom"
              formatter={(value) => {
                if (value === 'none') {
                  return t('notAvailable')
                }
                if (!value) {
                  return t('withoutCategory')
                }
                return value
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default TotalWorthProductTypeChart
