import { OptionTypes } from '../interfaces/form-options'

const userRoleOptions: OptionTypes[] = [
  {
    id: 1,
    value: 'AGENT',
    label: 'Agent'
  },
  {
    id: 2,
    value: 'DIRECTOR',
    label: 'Director'
  }
]

export default userRoleOptions
