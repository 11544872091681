import { createContext } from 'react'
import ProductsCartContextTypes from './productsCartsCtx.interface'

const productsCartCtx = createContext<ProductsCartContextTypes>({
  totalQuantity: 0,
  totalPrice: 0,
  taxPrice: 0,
  selectedProducts: [],
  addProduct: () => {},
  removeProduct: () => {},
  updateProductQuantity: () => {},
  updateProductBuyPrice: () => {},
  updateProductDiscountValues: () => {},
  updateProductLot: () => {},
  setSelectedProducts: () => {}
})

export default productsCartCtx
