import { useTranslation } from 'react-i18next'
import {
  Navigate, useNavigate, useSearchParams, Link
} from 'react-router-dom'
import { useEffect } from 'react'
import useMutateData from '../../hooks/useMutateData'
import apiService from '../../services/api/apiService'
import emoriaLogo2x from '../../assets/images/logo.png'
import PasswordForm from '../../components/Form/PasswordForm'
import { CreatePasswordFormTypes } from '../../interfaces/passwrod-form'
import routes from '../../constants/routes'

function ResetPassword() {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `Emoria CRM - ${t('resetPassword')}`
  }, [t])

  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('token')
  const navigate = useNavigate()

  const { mutate: resetPassword, isLoading } = useMutateData({
    key: 'resetPassword',
    mutationFn: apiService.resetPassword,
    successMessage: 'passwordHasBeenReset'
  })

  const onSubmit = (values: CreatePasswordFormTypes) => {
    resetPassword({ token: tokenParam!, password: values.password }, {
      onSuccess: () => {
        navigate(routes.login)
      }
    })
  }

  useEffect(() => {
    if (tokenParam) {
      localStorage.clear()
    }
  }, [tokenParam])

  if (!tokenParam) {
    return <Navigate to={routes.login} />
  }

  return (
    <div className="p-5 flex flex-col justify-center items-center w-full min-h-full">
      <Link to="/">
        <img src={emoriaLogo2x} width="332px" height="133px" alt="Emoria logo" className="mx-auto" />
      </Link>
      <div className="mx-auto bg-white mt-12 max-w-[500px] rounded-3xl shadow-faint w-full p-4 sm:p-12">
        <h1 className="text-center text-4xl  text-[#1B1B1B]">{`${t('resetPassword')}`}</h1>
        <p className="text-center text-base mx-7 my-4 text-grey-pale">
          {t('enterNewsPassword')}
        </p>
        <PasswordForm onSubmit={onSubmit} isLoading={isLoading} btnText="resetPassword" />
      </div>
    </div>
  )
}

export default ResetPassword
