import { createContext } from 'react'
import { DataProviderCtxTypes } from './dataProviderCtx.interface'

const dataProviderCtx = createContext<DataProviderCtxTypes>({
  clients: [],
  clientsCount: 0,
  showToughClients: false,
  clientPaginate: {
    fetchNextPage: async () => {},
    hasNextPage: false,
    isFetching: false,
    isFetchingNextPage: false
  },
  agents: [],
  products: [],
  tasks: [],
  searchTextClient: '',
  searchTextProducts: '',
  sortTextClient: '',
  sortingOrderClient: 'initial',
  isClientsLoading: false,
  isProductsLoading: false,
  isTasksLoading: false,
  setIsEnabled: () => {},
  setSearchTextClient: () => {},
  setSearchTextProducts: () => {},
  setSortTextClient: () => {},
  setSortingOrderClient: () => {},
  setShowToughClients: () => {},
  onSearchClientHandler: () => {},
  onSearchProductsHandler: () => {},
  onSortTextClientHandler: () => {},
  onSortingOrderClientHandler: () => {}
})

export default dataProviderCtx
