import { OrderProductTypes } from '../interfaces/products'

// eslint-disable-next-line import/prefer-default-export
export const calculateQuantity = (products: OrderProductTypes[]) => products
  .reduce((acc, prd) => acc + +prd.quantity, 0)

export const calculatePrice = (products: OrderProductTypes[]) => products
  .reduce((acc, prd) => acc + (+prd.quantity * prd.final_price), 0)

export const calculatePriceSingleViewPage = (products: OrderProductTypes[]) => products
  .reduce((acc, prd) => {
    const discount = +prd.discount1 + +prd.discount2
    return acc + (+prd.quantity * (prd.buy_price - (prd.buy_price * (discount / 100))))
  }, 0)

export const calculateTaxPrice = (products: OrderProductTypes[]) => products.reduce(
  (acc, prd) => {
    const taxRate = prd.product.product_tax.replace(/\D/g, '');
    const totalTaxPrice = +prd.quantity * prd.final_price * (+taxRate / 100)
    return acc + totalTaxPrice
  },
  0
)

export const calculateFinalPrice = (buyPrice: number, discount1:string, discount2:string) => {
  const discount = +discount1 + +discount2
  return buyPrice - (buyPrice * (discount / 100))
}

export const calculateTaxPriceSingleViewPage = (products: OrderProductTypes[]) => products.reduce(
  (acc, prd) => {
    const finalPrice = calculateFinalPrice(prd.buy_price, prd.discount1, prd.discount2)
    const taxRate = prd.product.product_tax.replace(/\D/g, '');
    const totalTaxPrice = +prd.quantity * finalPrice * (+taxRate / 100)
    return acc + totalTaxPrice
  },
  0
)
