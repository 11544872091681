import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useEffect } from 'react'
import PageTitle from '../../../../../components/UI/PageTitle'
import ClientCreationForm from '../components/ClientCreationForm'
import { ClientsOutletContextTypes } from '../../../../../interfaces/outlet-context'
import { ClientCreationTypes } from '../../../../../interfaces/client'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import PageSection from '../../../../../components/UI/PageSection'

function CreateClient() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    agents,
    clientCategories,
    clientCountries
  }: ClientsOutletContextTypes = useOutletContext()

  const { isLoading, mutate: createClient } = useMutateData({
    key: 'clients',
    mutationFn: apiService.createClient,
    successMessage: 'clientHasBeenCreated'
  })

  const onSubmit = (values: ClientCreationTypes) => {
    createClient(values, {
      onSuccess: async (client) => {
        await apiService.updateClientCallFrequency(
          { clientNumber: client?.data.data.client_number, frequency: +values.frequency! }
        )
        navigate(-1)
      }
    })
  }

  useEffect(() => {
    document.title = `Emoria CRM - ${t('New Client')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('New Client')} />
      <ClientCreationForm
        onSubmit={onSubmit}
        agents={agents}
        isLoading={isLoading}
        clientCategories={clientCategories}
        clientCountries={clientCountries}
      />
    </PageSection>
  )
}

export default CreateClient
