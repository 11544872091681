import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Detail from '../../../../../components/UI/Detail'
import { ClientOrdersRemainingTotal, ClientTypes } from '../../../../../interfaces/client'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  client?: ClientTypes
  clientOrdersRemainingTotal?: ClientOrdersRemainingTotal
  gridCols: 'lg:grid-cols-3' | 'lg:grid-cols-4'
  ellipsis?: 'line-clamp-1 text-ellipsis' | 'line-clamp-none'
  showInfo?: boolean
}

function ClientBasicInfo({
  client, gridCols, ellipsis, showInfo = true, clientOrdersRemainingTotal
}: Props) {
  const { t } = useTranslation()
  const print = 'print:grid-cols-3'
  return (
    <div className={`grid grid-cols-2 ${gridCols} gap-5 w-full ${print}`}>
      <Detail label={t('clientCode')} text={client?.client_code || '-'} ellipsis={ellipsis} />
      <RenderIf isTrue={showInfo}>
        <Detail label={t('dateCreatedClient')} text={client?.date_created ? moment(client?.date_created).format('DD/MM/YYYY') : '-'} ellipsis={ellipsis} />
      </RenderIf>
      <RenderIf isTrue={showInfo}>
        <Detail label={t('category')} text={client?.category || '-'} ellipsis={ellipsis} />
      </RenderIf>
      <Detail label={t('fullName')} text={client?.full_name || '-'} ellipsis={ellipsis} />
      <Detail label={t('Email')} text={client?.email || '-'} ellipsis={ellipsis} />
      <Detail label={t('vatNumber')} text={t(client?.vat_number || '-')} ellipsis={ellipsis} />
      <Detail label={t('irsBranch')} text={t(client?.irs_branch || '-')} ellipsis={ellipsis} />
      <Detail label={t('taxStatus')} text={t(client?.tax_exemption || '-')} ellipsis={ellipsis} />
      <Detail label={t('telephone')} text={client?.telephone || '-'} ellipsis={ellipsis} />
      <RenderIf isTrue={showInfo}>
        <Detail label={t('geoRegion')} text={client?.geo_region || '-'} ellipsis={ellipsis} />
      </RenderIf>
      <RenderIf isTrue={showInfo}>
        <Detail label={t('area')} text={t(client?.area || '-')} ellipsis={ellipsis} />
      </RenderIf>
      <Detail label={t('county')} text={t(client?.prefecture || '-')} ellipsis={ellipsis} />
      <Detail label={t('city')} text={t(client?.city || '-')} ellipsis={ellipsis} />
      <Detail label={t('address')} text={t(client?.address || '-')} ellipsis={ellipsis} />
      <Detail label={t('zipCode')} text={t(client?.zip || '-')} ellipsis={ellipsis} />
      <RenderIf isTrue={showInfo}>
        <Detail label={t('toughClient')} text={t(client?.tough_client ? 'yes' : 'no')} ellipsis={ellipsis} />
      </RenderIf>
      <Detail label={t('callFrequency')} text={client?.frequency?.toString() ?? '-'} ellipsis={ellipsis} />
      <RenderIf isTrue={!!clientOrdersRemainingTotal}>
        <Detail
          label={`${t('remainingTotal')}`}
          text={`${clientOrdersRemainingTotal?.total}€` ?? '-'}
        />
      </RenderIf>
      <RenderIf isTrue={showInfo}>
        <Detail label={t('paymentMethod')} text={t(client?.payment_method || '-')} ellipsis={ellipsis} />
      </RenderIf>
    </div>
  )
}

export default ClientBasicInfo
