import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import useGetData from '../../hooks/useGetData'
import { ClientTypeOfOrdersTypes } from '../../interfaces/statistics'
import apiService from '../../services/api/apiService'
import usePredefinedDateRange from '../../hooks/usePredefinedDateRange'
import ChartHeaderFilters from './ChartHeaderFilters'

interface Props {
  text: string,
  clientNumber: string,
  barSize?: number
}

function OrderKindsChart({ text, clientNumber, barSize = 30 }:Props) {
  const { t } = useTranslation()
  const {
    startDate, endDate, shouldFetchValue, setDateRange, setPredefinedDateRange, predefinedDateRange
  } = usePredefinedDateRange()

  const { data: typeOfOrders } = useGetData<ClientTypeOfOrdersTypes[]>({
    queryKey: `clientTypeOfOrders ${predefinedDateRange} ${clientNumber}`,
    queryFn: () => apiService.getClientTypeOfOrders({
      clientNumber,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    }),
    disabled: predefinedDateRange !== shouldFetchValue || !endDate
  })

  return (
    <div className="bg-[#FBFBFB] w-full rounded-[10px] p-[10px] md:py-5 md:px-8 h-[440px]">
      <ChartHeaderFilters
        text={text}
        predefinedDateRange={predefinedDateRange}
        endDate={endDate}
        startDate={startDate}
        setPredefinedDateRange={setPredefinedDateRange}
        setDateRange={setDateRange}
      />
      <ResponsiveContainer width="99%" height="88%">
        <BarChart
          data={typeOfOrders ?? []}
          barSize={barSize}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="category"
            dataKey="orderproduct__product__product_category"
            tickMargin={11}
            style={{
              fontSize: '10px'
            }}
          />
          <YAxis
            type="number"
          />
          <Tooltip
            formatter={
            (value, name) => {
              if (name === 'Ποσό') return `${Number(value).toFixed(3)}€`
              return value
            }
          }
          />
          <Legend
            payload={
              [{
                value: t('total'),
                color: '#000000'
              },
              {
                value: t('amount'),
                color: '#61A279'
              }]
            }
          />
          <Bar dataKey="count" fill="#000000" name={t('total')} />
          <Bar dataKey="sum" fill="#61A279" name={t('amount')} />

        </BarChart>
      </ResponsiveContainer>
    </div>

  );
}

export default OrderKindsChart
