import React from 'react'
import { useTranslation } from 'react-i18next'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import Spinner from '../../../../../components/UI/Spinner'

interface Props {
  isToughClient: boolean
  clientNumber: string
}
function ToughClient({ isToughClient, clientNumber } :Props) {
  const { t } = useTranslation()
  const { mutate, isLoading } = useMutateData({
    key: ['clients', `client-${clientNumber}`],
    mutationFn: apiService.updateToughClient
  })

  return (
    <div className="flex justify-start items-center gap-2">
      {isLoading && <Spinner alignment="start" />}

      {!isLoading && (
        <p
          title={t('checkHereForToughClient')}
        >
          <input
            type="checkbox"
            checked={isToughClient}
            onChange={
              () => mutate({
                clientNumber,
                isTough: !isToughClient
              })
            }
          />
        </p>
      )}
    </div>
  )
}

export default ToughClient
