import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../../../components/Form/FormInput'
import validationSchema from '../../../../../utils/validationSchema'
import { UserCreationTypes, UserTypes } from '../../../../../interfaces/user'
import RenderIf from '../../../../../components/UI/RenderIf'
import FormLayout from '../../../../../components/Form/FormLayout'
import FormButtons from '../../../../../components/Form/FormButtons'
import FormSelect from '../../../../../components/Form/FormSelect'
import userRoleOptions from '../../../../../helpers/user-options'
import UserMethods from '../../../../../utils/userMethods'

interface UserCreationProps {
  onSubmit: (values: UserCreationTypes) => void
  user?: UserTypes
  managers?: UserTypes[]
  isLoading: boolean
}

function UserCreationForm({
  onSubmit, user, isLoading, managers = []
}: UserCreationProps) {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()

  const initialValues: UserCreationTypes = {
    firstName: user ? user.user.first_name : '',
    lastName: user ? user.user.last_name : '',
    email: user ? user.user.email : '',
    phone: user ? user.user.telephone : '',
    username: user ? user.user.username : '',
    role: user ? user.user.user_type : 'AGENT',
    department: user ? user.department : ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: () => validationSchema.userCreationForm(formik.values.role, user),
    onSubmit
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout>
        <FormInput
          inputId="firstName"
          name="Employee Name"
          getFieldProps={formik.getFieldProps('firstName')}
          errors={
            !!(formik.touched.firstName && formik.errors.firstName)
          }
          errorName={formik.errors.firstName}
        />
        <FormInput
          inputId="lastName"
          name="Employee Surname"
          getFieldProps={formik.getFieldProps('lastName')}
          errors={
            !!(formik.touched.lastName && formik.errors.lastName)
          }
          errorName={formik.errors.lastName}
        />
        <FormInput
          inputId="email"
          name="Email"
          getFieldProps={formik.getFieldProps('email')}
          errors={!!(formik.touched.email && formik.errors.email)}
          errorName={formik.errors.email}
        />
        <FormInput
          inputId="phone"
          name="Phone"
          getFieldProps={formik.getFieldProps('phone')}
          errors={!!(formik.touched.phone && formik.errors.phone)}
          errorName={formik.errors.phone}
        />
        <FormInput
          inputId="username"
          name="Username"
          getFieldProps={formik.getFieldProps('username')}
          errors={
            !!(formik.touched.username && formik.errors.username)
          }
          errorName={formik.errors.username}
        />
        <RenderIf isTrue={formik.values.role === 'AGENT'}>
          <FormInput
            type="text"
            inputId="department"
            name="department"
            getFieldProps={formik.getFieldProps('department')}
            errors={
            !!(formik.touched.department && formik.errors.department)
          }
            errorName={formik.errors.department}
            disabled
          />
        </RenderIf>
        {/* User Role cannot be updated after user is created */}
        <RenderIf isTrue={!user && authUser?.user.user_type === 'DIRECTOR'}>
          <FormSelect
            labelText="Role"
            getFieldProps={formik.getFieldProps('role')}
            errors={
            !!(formik.touched.role && formik.errors.role)
          }
            errorName={formik.errors.role}
          >
            {userRoleOptions.map((role) => (
              <option
                key={role.id}
                value={role.value}
              >
                {t(role.label)}
              </option>
            ))}
          </FormSelect>
        </RenderIf>
      </FormLayout>
      <FormButtons isLoading={isLoading} btnText={user ? 'Update Employee' : 'Add Employee'} />
    </form>
  )
}

export default UserCreationForm
