import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'

function Logo() {
  return (
    <Link to="/">
      <img src={logo} alt="" className="h-14" />
    </Link>
  )
}

export default Logo
