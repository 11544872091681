import { useNavigate } from 'react-router-dom'
import WidgetTitle from '../UI/WidgetTitle'
import TaskButton from '../UI/TaskButton'
import routes from '../../constants/routes'
import LatestOrder from './LatestOrder'
import { ClientLatestOrderTypes, ClientTypes } from '../../interfaces/client'
import RenderIf from '../UI/RenderIf'
import NoDataMessage from '../UI/NoDataMessage'
import UserMethods from '../../utils/userMethods'

interface Props {
  latestOrders: ClientLatestOrderTypes[]
  client?: ClientTypes
  height?: 'max-h-[calc(100vh-540px)]' | 'h-max'
}

function LatestOrders({ latestOrders = [], client, height = 'max-h-[calc(100vh-540px)]' }: Props) {
  const authUser = UserMethods.getUser()
  const navigate = useNavigate()
  const navigateToOrdersPage = authUser?.user.user_type === 'DIRECTOR' ? routes.director.orders : routes.agent.orders
  const displayLatestOrders = latestOrders
    .map((latestOrder) => <LatestOrder key={latestOrder.order_number} latestOrder={latestOrder} />)
  return (
    <div className={`${height}  overflow-y-hidden`}>
      <div className="flex items-center gap-2 flex-wrap justify-between">
        <WidgetTitle text="latestOrders" />
        <div className="flex items-center gap-4">
          <TaskButton type="all" text="allOrders" onClickHandler={() => navigate(`${navigateToOrdersPage}?afm=${client?.vat_number}`)} />
          <TaskButton type="add" text="newOrder" onClickHandler={() => navigate(`/${authUser?.user.user_type.toLowerCase()}/orders/${routes.shared.createOrder}/${client?.client_number}`)} />
        </div>
      </div>
      <div className="bg-[#FBFBFB] w-full overflow-y-auto mt-5 px-8 rounded-[10px] max-h-[calc(100%-90px)] 2xl:max-h-[calc(100%-45px)]">
        <div className="mt-5 flex flex-col gap-3 ">
          {displayLatestOrders}
          <RenderIf isTrue={displayLatestOrders?.length === 0}>
            <NoDataMessage text="noLatestOrdersFound" />
          </RenderIf>
        </div>
      </div>
    </div>
  )
}

export default LatestOrders
