import {
  useMemo, useContext, useState, ChangeEvent, useCallback
} from 'react'
import {
  useInfiniteQuery
} from 'react-query'
import { ChildrenTypes } from '../../interfaces/children'
import useGetData from '../../hooks/useGetData'
import apiService from '../../services/api/apiService'
import { ProductTypes } from '../../interfaces/products'
import { TaskTypes } from '../../interfaces/tasks'
import tokenMethods from '../../utils/tokenMethods'
import { UserTypes } from '../../interfaces/user'
import userMethods from '../../utils/userMethods'
import { DataProviderCtxTypes } from './dataProviderCtx.interface'
import dataProviderCtx from './dataProviderCtx'
import useTableSorting from '../../hooks/useTableSorting'

export const useDataProvider = ():DataProviderCtxTypes => useContext(dataProviderCtx)

function DataProvider({ children }: ChildrenTypes) {
  const token = tokenMethods.getAccessToken()
  const authUser = userMethods.getUser()

  const {
    sortText: sortTextClient,
    setSortText: setSortTextClient,
    onSortingOrderHandler: onSortingOrderClientHandler,
    onSortTextHandler: onSortTextClientHandler,
    sortingOrder: sortingOrderClient,
    setSortingOrder: setSortingOrderClient,
    apiSortingOrder
  } = useTableSorting()

  const [searchTextClient, setSearchTextClient] = useState<string>('')
  const [searchTextProducts, setSearchTextProducts] = useState<string>('')
  const [isEnabled, setIsEnabled] = useState<boolean>(!!token)
  const [showToughClients, setShowToughClients] = useState<boolean>(false)

  const {
    data,
    isLoading: isClientsLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useInfiniteQuery(
    ['clients', searchTextClient, showToughClients, sortTextClient, sortingOrderClient],
    ({ pageParam }) => apiService.getClients({
      pageParam,
      searchText: searchTextClient,
      isTough: showToughClients,
      sortText: sortTextClient,
      ascending: apiSortingOrder
    }),
    {
      getNextPageParam: (lastPage) => lastPage?.next?.split('=').at(-1),
      enabled: isEnabled,
      refetchOnWindowFocus: false
    }
  )

  const clientPaginate = useMemo(() => ({
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  }), [
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  ])

  const clients = useMemo(() => data?.pages.map((group) => group?.results ?? [])
    .flat(1) ?? [], [data])

  const onSearchClientHandler = useCallback((event:ChangeEvent<HTMLInputElement>) => {
    setSearchTextClient(event.target.value.trim())
  }, [])

  const onSearchProductsHandler = useCallback((event:ChangeEvent<HTMLInputElement>) => {
    setSearchTextProducts(event.target.value.trim())
  }, [])

  // Get agents data
  const { data: agents } = useGetData<UserTypes[]>({
    queryKey: 'agents',
    queryFn: apiService.getAgents,
    disabled: authUser?.user.user_type === 'AGENT'
  })

  // Get products
  const { data: products, isLoading: isProductsLoading } = useGetData<ProductTypes[]>({
    queryKey: `products ${searchTextProducts}`,
    queryFn: () => apiService.getProducts(searchTextProducts),
    disabled: !isEnabled,
    refetchInterval: 60000,
    cacheTime: 60000
  })

  // Get tasks
  const { data: tasks, isLoading: isTasksLoading } = useGetData<TaskTypes[]>({
    queryKey: 'tasks',
    queryFn: apiService.getTasks,
    disabled: !isEnabled
  })

  const ctx = useMemo(
    () => ({
      agents,
      clients,
      clientsCount: data?.pages?.[0]?.count ?? 0,
      showToughClients,
      products,
      tasks,
      searchTextClient,
      searchTextProducts,
      sortTextClient,
      sortingOrderClient,
      isClientsLoading,
      isProductsLoading,
      isTasksLoading,
      clientPaginate,
      setIsEnabled,
      setSearchTextClient,
      setSearchTextProducts,
      setSortTextClient,
      setSortingOrderClient,
      onSearchProductsHandler,
      setShowToughClients,
      onSearchClientHandler,
      onSortTextClientHandler,
      onSortingOrderClientHandler
    }),
    [
      agents,
      clients,
      data?.pages,
      showToughClients,
      products,
      tasks,
      searchTextClient,
      searchTextProducts,
      sortTextClient,
      sortingOrderClient,
      isTasksLoading,
      isClientsLoading,
      isProductsLoading,
      clientPaginate,
      setIsEnabled,
      setSearchTextClient,
      setSearchTextProducts,
      setSortTextClient,
      setSortingOrderClient,
      onSearchProductsHandler,
      setShowToughClients,
      onSearchClientHandler,
      onSortTextClientHandler,
      onSortingOrderClientHandler
    ]
  )

  return (
    <dataProviderCtx.Provider value={ctx}>
      {children}
    </dataProviderCtx.Provider>
  )
}

export default DataProvider
