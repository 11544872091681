import { useTranslation } from 'react-i18next'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { UserTypes } from '../../../interfaces/user'

interface Props {
  agents: UserTypes[]
  selectedAgent: string
  setSelectedAgent: Dispatch<SetStateAction<string>>
}
function SelectAgent({ agents, selectedAgent, setSelectedAgent }: Props) {
  const { t } = useTranslation()

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedAgent(event.target.value)
  }

  return (
    <select value={selectedAgent} onChange={handleChange} className="rounded-lg px-1 h-[46px] w-full sm:w-max text-grey-pale border border-[#BEBEBE] bg-white">
      <option value="all">{t('all')}</option>
      {agents?.map((agent) => (
        <option key={agent.id} value={agent.id.toString()}>
          {agent.user.first_name}
          {' '}
          {agent.user.last_name}
        </option>
      ))}
    </select>
  )
}

export default SelectAgent
