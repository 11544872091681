import {
  useContext, useCallback, useState, useEffect, useMemo,
  ChangeEvent
} from 'react'
import { ChildrenTypes } from '../../interfaces/children'
import { ProductTypes, OrderProductTypes } from '../../interfaces/products'
import { calculatePrice, calculateQuantity, calculateTaxPrice } from '../../helpers/calculate-price'
import ProductsCartContextTypes from './productsCartsCtx.interface'
import productsCartCtx from './productsCartCtx'
import useGetData from '../../hooks/useGetData'
import apiService from '../../services/api/apiService'

export const useProductsCart = ():ProductsCartContextTypes => useContext(productsCartCtx)

function ProductsCartProvider({ children }: ChildrenTypes) {
  const [selectedProducts, setSelectedProducts] = useState<OrderProductTypes[]>([])
  const [totalQuantity, setTotalQuantity] = useState<number>(0)
  const [taxPrice, setTaxPrice] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState<number>(0)

  // Get ids of selected products
  // @ts-ignore
  const selectedProductIds = useMemo(() => [...new Set(selectedProducts
    .map((prd) => +prd.product.product_id))], [selectedProducts])

  // Update stock of selected products every 10 seconds
  const { data: updatedStockProducts } = useGetData<ProductTypes[]>({
    queryKey: `productsStock ${selectedProductIds}`,
    queryFn: () => apiService.updateStock(selectedProductIds),
    disabled: selectedProducts.length === 0,
    cacheTime: 0,
    refetchInterval: 20000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchIntervalInBackground: true
  })

  const calculatePriceAfterDiscount = (price: number, discount1 = 0, discount2 = 0) => {
    const totalDiscount = discount1 + discount2
    const discountPrice = (price * totalDiscount) / 100

    return price - discountPrice
  }

  const addProduct = useCallback((product: ProductTypes) => {
    setSelectedProducts(
      (prevState) => [...prevState, {
        product,
        quantity:
            product.remaining_stock <= 0 ? 0 : 1,
        buy_price: product.price,
        final_price: calculatePriceAfterDiscount(
          product.price,
          +product.discount1,
          +product.discount2
        ),
        lot: '',
        discount1: product.discount1,
        discount2: product.discount2
      }
      ]
    )
  }, [])

  const removeProduct = useCallback((sku: ProductTypes['product_sku'], index: number) => {
    // Remove product from the cart,
    // filter is not suitable here because we can have duplicate products
    const updatedProducts = [
      ...selectedProducts
        .slice(0, index), ...selectedProducts.slice(index + 1)
    ]

    setSelectedProducts(updatedProducts)
  }, [selectedProducts])

  const updateProductQuantity = useCallback((
    event: ChangeEvent<HTMLInputElement>,
    product:OrderProductTypes,
    index:number
  ) => {
    // Return if the value is not a number or the value is higher than remaining stock
    const onlyNumRegExp = /^\d+$/;
    if (
      +event.target.value > product.product.remaining_stock
      || !onlyNumRegExp.test(event.target.value)
    ) return

    // Find index of the added product
    const findProductIndex = selectedProducts
      .findIndex((prd, i) => prd.product.product_sku === product.product.product_sku && i === index)
    const existingProduct = selectedProducts[findProductIndex]
    // Increase/Decrease quantity
    existingProduct.quantity = +event.target.value
    // Update state
    const updatedProducts = [...selectedProducts]
    updatedProducts[findProductIndex] = existingProduct
    setSelectedProducts(updatedProducts)
  }, [selectedProducts])

  const updateProductLot = useCallback((
    event: ChangeEvent<HTMLInputElement>,
    product:OrderProductTypes,
    index:number
  ) => {
    // Find index of the added product
    const findProductIndex = selectedProducts
      .findIndex((prd, i) => product.product.product_sku === prd.product.product_sku && i === index)
    const existingProduct = selectedProducts[findProductIndex]
    // Add lot text
    existingProduct.lot = event.target.value
    // Update state
    const updatedProducts = [...selectedProducts]
    updatedProducts[findProductIndex] = existingProduct
    setSelectedProducts(updatedProducts)
  }, [selectedProducts])

  const updateProductDiscountValues = useCallback((
    product:OrderProductTypes,
    index:number,
    event1?:
    ChangeEvent<HTMLInputElement>,
    event2?:
    ChangeEvent<HTMLInputElement>
  ) => {
    const updatedProducts = selectedProducts.map((prd, i) => {
      const newProduct = JSON.parse(JSON.stringify(prd)) as OrderProductTypes
      if (newProduct.product.product_sku === product.product.product_sku && i === index) {
        let discount1 = 0
        let discount2 = 0
        if (event1) {
          discount1 = +event1.target.value
          newProduct.discount1 = discount1.toString()
        }
        if (event2) {
          discount2 = +event2.target.value
          newProduct.discount2 = discount2.toString()
        }
        newProduct.final_price = calculatePriceAfterDiscount(
          newProduct.buy_price,
          +newProduct.discount1,
          +newProduct.discount2
        )
        return newProduct
      }
      return prd
    })
    setSelectedProducts(updatedProducts)
  }, [selectedProducts])

  const updateProductBuyPrice = useCallback(
    (
      event:
      ChangeEvent<HTMLInputElement>,
      product:OrderProductTypes,
      index:number
    ) => {
      if (event.target.value === '') return
      // Find index of the added product
      const findProductIndex = selectedProducts
        .findIndex(
          (prd, i) => prd.product.product_sku === product.product.product_sku && i === index
        )
      const existingProduct = selectedProducts[findProductIndex]
      // Update Buy Price
      existingProduct.buy_price = +event.target.value

      // Update Final Price
      existingProduct.final_price = calculatePriceAfterDiscount(
        +event.target.value,
        +existingProduct.discount1,
        +existingProduct.discount2
      )

      // Update state
      const updateProducts = [...selectedProducts]
      updateProducts[findProductIndex] = existingProduct
      setSelectedProducts(updateProducts)
    },
    [selectedProducts]
  )

  // Get total quantity, total and tax price of the selected products
  useEffect(() => {
    if (selectedProducts.length !== 0) {
      const getTotalQuantity = calculateQuantity(selectedProducts)
      const getTotalPrice = calculatePrice(selectedProducts)
      const getTaxPrice = calculateTaxPrice(selectedProducts)

      setTotalQuantity(getTotalQuantity)
      setTotalPrice(getTotalPrice)
      setTaxPrice(getTaxPrice)
    } else {
      setTotalQuantity(0)
      setTotalPrice(0)
      setTaxPrice(0)
    }
  }, [selectedProducts])

  useEffect(() => {
    if (updatedStockProducts) {
      const updatedProducts = selectedProducts.map((prd) => {
        const product = { ...prd }
        const updatedProduct = updatedStockProducts
          .find((updatedPrd) => updatedPrd.product_id === product.product.product_id)
        if (updatedProduct) {
          product.product.remaining_stock = updatedProduct.remaining_stock
        }
        return product
      })
      setSelectedProducts(updatedProducts)
    }

    // Don't add selectedProducts to dependency Array
  }, [updatedStockProducts])

  const ctx = useMemo(
    () => ({
      selectedProducts,
      totalQuantity,
      totalPrice,
      taxPrice,
      addProduct,
      removeProduct,
      updateProductQuantity,
      updateProductBuyPrice,
      updateProductDiscountValues,
      updateProductLot,
      setSelectedProducts
    }),
    [
      selectedProducts,
      totalQuantity,
      totalPrice,
      taxPrice,
      addProduct,
      removeProduct,
      updateProductQuantity,
      updateProductBuyPrice,
      updateProductDiscountValues,
      updateProductLot,
      setSelectedProducts
    ]
  )

  return (
    <productsCartCtx.Provider value={ctx}>{children}</productsCartCtx.Provider>
  )
}

export default ProductsCartProvider
