import WidgetTitle from '../UI/WidgetTitle'
import CallHistory from './CallHistory'
import { ClientCallHistoryTypes } from '../../interfaces/client'
import RenderIf from '../UI/RenderIf'
import NoDataMessage from '../UI/NoDataMessage'

interface Props {
  callHistory: ClientCallHistoryTypes[]
  height: 'max-h-[492px]' | 'max-h-[calc(100vh-535px)]'
}

function CallsHistory({ callHistory = [], height = 'max-h-[calc(100vh-535px)]' }: Props) {
  const displayCallHistory = callHistory
    .map((call) => (
      <CallHistory
        key={call.id}
        callHistory={call}
      />
    ))

  return (
    <div className={`bg-white overflow-y-hidden ${height}`}>
      <div className="px-2 mb-5 flex items-center gap-2 flex-wrap justify-between">
        <WidgetTitle text="callsHistory" />
      </div>
      <div className="overflow-y-auto h-[calc(100%-48px)] w-full px-8">
        <div className="mt-5 flex flex-col h-[calc(100%-60px)]">
          {displayCallHistory}
          <RenderIf isTrue={callHistory.length === 0}>
            <NoDataMessage text="noHistoryCallFound" />
          </RenderIf>
        </div>
      </div>
    </div>
  )
}

export default CallsHistory
