import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import ClientBasicDetails from '../components/ClientBasicDetails'
import PageSection from '../../../../../components/UI/PageSection'
import DetailsInfoBtns from '../../../../../components/UI/DetailsInfoBtns'
import routes from '../../../../../constants/routes'
import UserMethods from '../../../../../utils/userMethods'
import Title from '../../../../../components/UI/Title'
import FrequencyOfOrdersChart from '../../../../../components/Charts/FrequencyOfOrdersChart'
import OrderKindsChart from '../../../../../components/Charts/OrderKindsChart'
import useGetData from '../../../../../hooks/useGetData'
import { ClientTypes } from '../../../../../interfaces/client'
import apiService from '../../../../../services/api/apiService'
import RenderIf from '../../../../../components/UI/RenderIf'
import ClientInfoSkeleton from '../../../../../components/Skeletons/ClientInfoSkeleton'
import TradeAgreementFilePreview from '../components/TradeAgreementFilePreview'
import PrintButton from '../../../../../components/UI/PrintButton'
import CallHistoryContainer from '../../../../../components/ClientCallsHistory/CallHistoryContainer'
import LatestOrdersContainer from '../../../../../components/ClientLatestOrders/LatestOrdersContainer'
import AverageOfOrders from '../components/AverageOfOrders'
import ClientTotalWorthOfOrders from '../components/ClientTotalWorthOfOrders'
import ClientCall from '../components/ClientCall'
import ClientCommentsContainer from '../components/ClientCommentsContainer'
import ClientOrdersDetailsContainer from '../components/ClientOrdersDetailsContainer'
import ClientTabsModal from '../components/ClientTabsModal'

function UserInfo() {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()

  const navigate = useNavigate()
  const params = useParams()

  const { data: client, isLoading } = useGetData<ClientTypes>({
    queryKey: `client-${params.clientNumber}`,
    queryFn: () => apiService.getClientByClientNumber(params.clientNumber!),
    disabled: !params.clientNumber
  })

  const clientPath = `/${authUser?.user.user_type.toLowerCase()}/clients/${routes.shared.updateClient}/${client?.client_number}`
  const h3Classes = 'text-primary-text-dark text-[20px] font-semibold'
  const disablePrint = 'print:hidden'

  useEffect(() => {
    document.title = `Emoria CRM - ${t('client')}`
  }, [t])

  return (
    <>
      <PageSection>
        <RenderIf isTrue={isLoading}>
          <ClientInfoSkeleton />
        </RenderIf>
        <RenderIf isTrue={!isLoading}>
          <div>
            <div className="flex">
              <Title
                title="client"
                add="Add Order"
                onClickHandler={() => navigate(`/${authUser?.user.user_type.toLowerCase()}/orders/${routes.shared.createOrder}/${client?.client_number}`)}
                marginTop="mt-0"
                marginBottom="mb-8"
              >
                <p className="text-primary-text-dark text-3xl sm:translate-y-[5px]">{client?.client_code}</p>
                <p className="bg-[#EEF4FC] text-[#2571C6] text-xl rounded-[12px] sm:translate-y-[6px] px-5 py-2">
                  {`${client?.agent.user.first_name} ${client?.agent.user.last_name}`}
                </p>
              </Title>
              <PrintButton usedInTitle />
            </div>
            <ClientBasicDetails client={client} />
            <ClientOrdersDetailsContainer client={client} />
          </div>
          <div className="mt-5 flex flex-col gap-5">
            <div>
              <h3 className={h3Classes}>{t('tradeAgreement')}</h3>
            </div>

            <RenderIf isTrue={client?.client_agreement.length !== 0}>
              <TradeAgreementFilePreview file={client?.client_agreement[0]} isClientInfo />
            </RenderIf>
            <RenderIf isTrue={client?.client_agreement.length === 0}>
              <p className="text-grey-pale text-sm">{t('noTradeAgreementHasBeenSubmitted')}</p>
            </RenderIf>
          </div>

          <div className={`grid  mt-8 gap-5 ${authUser?.user.user_type === 'AGENT' ? 'lg:grid-cols-2' : 'lg:grid-cols-1'}`}>
            <ClientCommentsContainer client={client} />
            <RenderIf isTrue={authUser?.user.user_type === 'AGENT'}>
              <ClientCall client={client} />
            </RenderIf>
          </div>

          <div className="grid lg:grid-cols-2 mt-8 gap-5">
            <LatestOrdersContainer height="h-max" client={client} />
            <CallHistoryContainer height="max-h-[492px]" clientNumber={client?.client_number} />
          </div>

          <div className={`flex flex-col gap-6 my-6 ${disablePrint}`}>
            <h3 className={h3Classes}>{t('statisticDetails')}</h3>

            <div className="flex flex-col lg:flex-row lg:justify-between gap-6">
              <FrequencyOfOrdersChart text="frequencyOfOrders" clientNumber={client?.client_number} />
              <AverageOfOrders clientNumber={client?.client_number} />
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between gap-6">
              <ClientTotalWorthOfOrders clientNumber={client?.client_number} />
              <OrderKindsChart text="orderKinds" clientNumber={client?.client_number} />
            </div>

          </div>
          <DetailsInfoBtns btnText="editClient" onClickHandler={() => navigate(clientPath)} />
        </RenderIf>
      </PageSection>
      <ClientTabsModal />
    </>
  )
}

export default UserInfo
