import React from 'react'
import { useTranslation } from 'react-i18next'
import { ClientTabTypes } from '../../../../../interfaces/client'

interface Props {
  clientTabItems: ClientTabTypes[]
}
function TotalTabsItemsValue({ clientTabItems = [] }:Props) {
  const { t } = useTranslation()
  const calculateTotalValue = () => clientTabItems
    .reduce((acc, item) => acc + item.value, 0).toFixed(2)

  const calculateTotalTaxValue = () => clientTabItems
    .reduce((acc, item) => acc + item.tax_value, 0).toFixed(2)

  return (
    <div className="bg-white rounded-2xl shadow-faint py-5 px-4 sm:px-10 flex justify-between font-semibold">
      <div>
        <p>Σύνολο</p>
      </div>
      <div className="flex gap-5 sm:mr-[4%]">
        <div className="flex flex-col gap-2">
          <p>{t('value')}</p>
          <p className="font-normal">
            {calculateTotalValue()}
          </p>
        </div>

        <div className="flex flex-col gap-2 ">
          <p>{t('taxValue')}</p>
          <p className="font-normal">
            {calculateTotalTaxValue()}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TotalTabsItemsValue
