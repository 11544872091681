import { ClientOrdersDetails, ClientTypes } from '../../../../../interfaces/client'
import ClientOrdersDetailsTable from './ClientOrdersDetailsTable'
import TableSkeleton from '../../../../../components/Skeletons/TableSkeleton'
import useGetData from '../../../../../hooks/useGetData'
import apiService from '../../../../../services/api/apiService'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  client?:ClientTypes
}
function ClientOrdersDetailsContainer({ client }:Props) {
  const { data: clientOrdersDetails, isLoading } = useGetData<ClientOrdersDetails[]>({
    queryKey: `clientOrdersDetails ${client?.client_number}`,
    queryFn: () => apiService.getClientOrdersDetails({ clientNumber: client?.client_number! })
  })

  return (
    <>
      <RenderIf isTrue={isLoading}>
        <TableSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isLoading}>
        <ClientOrdersDetailsTable clientOrdersDetails={clientOrdersDetails} />
      </RenderIf>
    </>
  )
}

export default ClientOrdersDetailsContainer
