import ReactDOM from 'react-dom'
import { IoClose } from 'react-icons/io5'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'
import useGetData from '../../../../../hooks/useGetData'
import apiService from '../../../../../services/api/apiService'
import ClientTabsTable from './ClientTabsTable'
import { ClientTabTypes } from '../../../../../interfaces/client'
import RenderIf from '../../../../../components/UI/RenderIf'
import TableSkeleton from '../../../../../components/Skeletons/TableSkeleton'
import NoDataMessage from '../../../../../components/UI/NoDataMessage'
import TotalTabsItemsValue from './TotalTabItemsValue'

function ClientTabsModal() {
  const { showClientsTabModal, resourceId, closeClientsTabModalHandler } = useModalContext()
  const { data: clientTabItems, isLoading } = useGetData<ClientTabTypes[]>({
    queryKey: `client-tabs-${resourceId?.id}`,
    queryFn: () => apiService.getClientsTabItems(resourceId?.id?.toString()!),
    disabled: !resourceId?.id
  })

  const backdropClasses = showClientsTabModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = showClientsTabModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'

  const overlays = document.getElementById('overlays') as HTMLElement

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div onClick={closeClientsTabModalHandler} className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} />
      <div className={`p-5 bg-white rounded-2xl ${modalClasses} overflow-y-auto max-h-[90svh] w-[90%] z-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}>
        <IoClose className="ml-auto" size={22} onClick={closeClientsTabModalHandler} cursor="pointer" />
        <RenderIf isTrue={isLoading}>
          <TableSkeleton />
        </RenderIf>

        <RenderIf isTrue={!isLoading && clientTabItems?.length > 0}>
          <div className="flex flex-col gap-5">
            <ClientTabsTable clientTabItems={clientTabItems} />
            <TotalTabsItemsValue clientTabItems={clientTabItems} />
          </div>
        </RenderIf>

        <RenderIf isTrue={!isLoading && clientTabItems?.length === 0}>
          <NoDataMessage text="noTabItems" />
        </RenderIf>
      </div>
    </>,
    overlays
  )
}

export default ClientTabsModal
