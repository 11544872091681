import { useTranslation } from 'react-i18next'
import { IoMdRemoveCircle } from 'react-icons/io'
import { ChangeEvent } from 'react'
import AddProductHeaderValue from './AddProductHeaderValue'
import AddProductValue from './AddProductValue'
import { OrderProductTypes, ProductTypes } from '../../../../../interfaces/products'
import ProductsList from './ProductsList'
import { useProductsCart } from '../../../../../context/ProductsCartCtx/ProductsCartProvider'
import StockIcon from '../../../../../components/UI/StockIcon'
import OrderTotalAmount from './OrderTotalAmount'
import RenderIf from '../../../../../components/UI/RenderIf'
import { calculateFinalPrice } from '../../../../../helpers/calculate-price'

interface Props {
  products: ProductTypes[]
  orderProducts?: OrderProductTypes[]
  orderTotalPrice?: string
}

function AddProductsToOrder({ products = [], orderProducts, orderTotalPrice }: Props) {
  const {
    selectedProducts,
    addProduct,
    removeProduct,
    updateProductQuantity,
    updateProductDiscountValues,
    updateProductBuyPrice,
    updateProductLot
  } = useProductsCart()

  const { t } = useTranslation()
  const print = 'print:overflow-visible print:w-[900px]'

  const quantityText = (product:OrderProductTypes) => {
    if (product.uncompleted_quantity! > 0) return `${+product.quantity - product.uncompleted_quantity!}/${product.quantity} τμχ`
    return `${product.quantity} τμχ`
  }

  const getPercentage = (vat: string) => {
    const pattern = /\b\d+%/;

    const matches = vat.match(pattern);

    return matches?.[0] ?? ''
  }

  return (
    <div>
      <h3 className="text-grey-bold text-xl">{t('products')}</h3>
      <div className="bg-[#FBFBFB] rounded-xl w-full mt-5 pb-5 ">
        <div className="overflow-x-auto print:overflow-x-visible">
          <div className="min-w-[1765px] print:text-[10px]">
            <div className={`flex justify-between w-full pt-5 pb-2 border-b border-solid border-b-[#d4d4d4] px-7 ${print}`}>
              <div className="flex  gap-[115px]">
                <AddProductHeaderValue text="SKU" />
                <AddProductHeaderValue text="product" />
              </div>
              <div className="flex gap-[40px] whitespace-nowrap">
                <RenderIf isTrue={!orderProducts}>
                  <AddProductHeaderValue text="lot" width={orderProducts ? '-translate-x-[35px]' : 'translate-x-0'} />
                </RenderIf>
                <AddProductHeaderValue text="quantity" width={orderProducts ? '-translate-x-[35px]' : 'translate-x-0'} />
                <RenderIf isTrue={!orderProducts}>
                  <AddProductHeaderValue text="stock" />
                </RenderIf>
                <AddProductHeaderValue text="initialPrice" />
                <AddProductHeaderValue text="discount1" />
                <AddProductHeaderValue text="discount2" />
                <AddProductHeaderValue text="tax" width="translate-x-[20px]" />
                <AddProductHeaderValue text="finalPrice" />
              </div>
            </div>

            {(orderProducts ?? selectedProducts).map((product, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={`flex justify-between pt-5 pb-2 items-center relative px-7 ${print}`} key={product.product.product_sku + product.quantity + i}>
                <div className="flex items-center">
                  <RenderIf isTrue={!orderProducts}>
                    <IoMdRemoveCircle
                      size={18}
                      className="h-max absolute left-1 cursor-pointer text-error"
                      onClick={() => removeProduct(product.product.product_sku, i)}
                    />
                  </RenderIf>

                  <AddProductValue
                    width="w-[145px]"
                    text={product.product.product_sku}
                    isUncompleted={product.uncompleted_quantity! > 0}
                  />
                  <AddProductValue
                    width="w-[600px]"
                    text={product.product.product_name}
                    isUncompleted={product.uncompleted_quantity! > 0}
                  />
                </div>
                <div className={`grid ${orderProducts ? 'grid-cols-6' : 'grid-cols-8'} gap-6 items-center`}>
                  <RenderIf isTrue={!orderProducts}>
                    <AddProductValue
                      isLot
                      text={product.lot}
                      type="text"
                      value={product.lot}
                      disabled={!!orderProducts}
                      onChangeHandler={
                      (event:
                      ChangeEvent<HTMLInputElement>) => updateProductLot(event, product, i)
                    }
                      style={{
                        width: '100px'
                      }}
                    />
                  </RenderIf>

                  <AddProductValue
                    text={orderProducts ? quantityText(product) : product.quantity}
                    type={orderProducts ? 'text' : 'number'}
                    inputText="τμχ"
                    min={product.product.remaining_stock !== 0 ? 1 : 0}
                    max={product.product.remaining_stock}
                    value={product.quantity}
                    disabled={!!orderProducts}
                    onChangeHandler={
                    (event:
                    ChangeEvent<HTMLInputElement>) => updateProductQuantity(event, product, i)
                  }
                    style={{
                      width: '70px'
                    }}
                    isUncompleted={product.uncompleted_quantity! > 0}
                  />

                  <RenderIf isTrue={!orderProducts}>
                    <AddProductValue
                      width="ml-7"
                      text={
                      (
                        <span className="flex items-center gap-1">
                          <StockIcon stock={product.product.remaining_stock} />
                          {product.product.remaining_stock}
                        </span>
                      )
                    }
                    />
                  </RenderIf>
                  <AddProductValue
                    text={product.buy_price}
                    type="number"
                    inputText="€"
                    min={0}
                    step="0.01"
                    value={product.buy_price.toFixed(2)}
                    disabled={!!orderProducts}
                    onChangeHandler={
                      (event:
                      ChangeEvent<HTMLInputElement>) => updateProductBuyPrice(event, product, i)
                    }
                    style={{
                      width: '90px',
                      marginLeft: 'auto'
                    }}
                  />

                  <AddProductValue
                    width="ml-10"
                    text={orderProducts ? `${product.discount1}%` : product.discount1}
                    type={orderProducts ? 'text' : 'number'}
                    inputText="%"
                    min={0}
                    max={100}
                    value={product.product.discount1}
                    disabled={!!orderProducts}
                    onChangeHandler={
                    (event:
                    ChangeEvent<HTMLInputElement>) => updateProductDiscountValues(product, i, event)
                  }
                    style={{
                      width: '60px',
                      marginLeft: '30px'
                    }}
                  />

                  <AddProductValue
                    width="ml-10"
                    text={orderProducts ? `${product.discount2}%` : product.discount2}
                    type={orderProducts ? 'text' : 'number'}
                    inputText="%"
                    min={0}
                    max={100}
                    value={product.product.discount2}
                    disabled={!!orderProducts}
                    onChangeHandler={
                    (event:
                    ChangeEvent<HTMLInputElement>) => updateProductDiscountValues(
                      product,
                      i,
                      undefined,
                      event
                    )
                  }
                    style={{
                      width: '60px',
                      marginLeft: '30px'
                    }}
                  />
                  <AddProductValue
                    width="max-w-[40px] justify-self-end"
                    text={getPercentage(product.product.product_tax)}
                  />
                  <AddProductValue
                    text={product.buy_price}
                    type="number"
                    inputText="€"
                    min={0}
                    step="0.01"
                    value={orderProducts
                      ? calculateFinalPrice(
                        product.buy_price,
                        product.discount1,
                        product.discount2
                      ).toFixed(2)
                      : product.final_price?.toFixed(2)}
                    disabled
                    style={{
                      width: '90px',
                      marginLeft: 'auto'
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <ProductsList products={products} orderProducts={orderProducts} addProduct={addProduct} />
      </div>
      <OrderTotalAmount orderTotalPrice={orderTotalPrice} orderProducts={orderProducts} />
    </div>
  )
}

export default AddProductsToOrder
