/* eslint-disable max-len */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import DatePicker from 'react-datepicker'
import { MdPictureAsPdf } from 'react-icons/md';
import Title from '../../../components/UI/Title'
import PageSection from '../../../components/UI/PageSection'
import { useDataProvider } from '../../../context/DataProviderCtx/DataProvider'
import SelectAgent from '../components/SelectAgent'
import TotalWorthProductTypeChart from '../../../components/Charts/TotalWorthProductTypeChart'
import NumberOfOrdersByProductTypeChart from '../../../components/Charts/NumberOfOrdersByProductTypeChart'
import TotalOrderValues from '../../../components/Charts/TotalOrderValues'
import CallStatistics from './CallStatistics'
import ChartPredefinedDateRanges from '../../../components/Charts/ChartPredefinedDateRanges'
import RenderIf from '../../../components/UI/RenderIf'
import CustomDatePicker from '../../../components/CustomDatePicker/DatePicker'
import usePredefinedDateRange from '../../../hooks/usePredefinedDateRange'
// import PrintButton from '../../../components/UI/PrintButton'

function Statistics() {
  const { t } = useTranslation()
  const { agents } = useDataProvider()
  const [selectedAgent, setSelectedAgent] = useState<string>('all')

  const predefinedDateRange = usePredefinedDateRange()

  useEffect(() => {
    document.title = `Emoria CRM - ${t('statistics')}`
  }, [t])

  const generatePDF = () => {
    const element = document.getElementById('component-to-export');
    if (element) {
      html2canvas(element)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new JsPDF();
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save('statistics.pdf');
        });
    }
  };

  return (
    <PageSection>
      <div className="flex justify-between items-center gap-3 sm:gap-2 flex-wrap md:flex-nowrap mt-5">
        <Title title="statistics" add="" marginTop="mt-0" />
        <div className="flex flex-row gap-2 sm:gap-1 sm:items-end md:-translate-y-[20px]">
          <div className="flex flex-col sm:flex-row sm:items-end sm:gap-1">
            <div className="flex flex-col gap-2">
              <h3 className="text-base sm:text-xl">{t('choosePeriod')}</h3>
              <ChartPredefinedDateRanges
                predefinedDateRange={predefinedDateRange.predefinedDateRange}
                setPredefinedDateRange={predefinedDateRange.setPredefinedDateRange}
              />
            </div>
            <RenderIf isTrue={predefinedDateRange.predefinedDateRange === 'selectDateRange'}>
              <DatePicker
                disabled={predefinedDateRange.predefinedDateRange !== 'selectDateRange'}
                locale="el"
                dateFormat="dd/MM/yyyy"
                calendarClassName="bg-white"
                maxDate={new Date()}
                selectsRange
                startDate={predefinedDateRange.startDate}
                placeholderText="Select Date Range"
                endDate={predefinedDateRange.endDate}
                onChange={(update) => {
                  if (predefinedDateRange.predefinedDateRange === 'selectDateRange') {
                    predefinedDateRange.setDateRange(update as Date[])
                  }
                }}
                customInput={<CustomDatePicker />}
              />
            </RenderIf>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-base sm:text-xl">{t('chooseAgent')}</h3>
            <SelectAgent agents={agents} setSelectedAgent={setSelectedAgent} selectedAgent={selectedAgent} />
          </div>
          <div className="flex flex-col gap-2">
            <button type="button" className="w-max h-max px-4 py-3 border-solid rounded-[5px] text-grey-bold text-lg border border-[#BEBEBE] bg-[#FFF]" onClick={() => generatePDF()}>
              <MdPictureAsPdf />
            </button>
          </div>
        </div>
      </div>
      <div id="component-to-export" className="grid xl:grid-cols-2 bg-white rounded-2xl shadow-md p-4 sm:p-8 mt-5 gap-6 print:w-[875px]">
        <NumberOfOrdersByProductTypeChart predefinedDateRange={predefinedDateRange} text="numberOfOrderByProductType" agent={selectedAgent} gridCss="row-span-2" />
        <TotalOrderValues predefinedDateRange={predefinedDateRange} agent={selectedAgent} gridCss="row-span-1" />
        <TotalWorthProductTypeChart predefinedDateRange={predefinedDateRange} agent={selectedAgent} gridCss="row-span-2" />
        <CallStatistics predefinedDateRange={predefinedDateRange} agent={selectedAgent} gridCss="row-span-1" />
      </div>
    </PageSection>
  )
}

export default Statistics
