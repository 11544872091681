import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Title from '../../../../components/UI/Title'
import ProductsTable from './components/ProductsTable'
import RenderIf from '../../../../components/UI/RenderIf'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import { useDataProvider } from '../../../../context/DataProviderCtx/DataProvider'
import routes from '../../../../constants/routes'

export default function Products() {
  const {
    products, isProductsLoading, setSearchTextProducts, searchTextProducts, onSearchProductsHandler
  } = useDataProvider()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.director.products
    || pathname === routes.agent.products
  const [t] = useTranslation()

  useEffect(() => {
    document.title = `Emoria CRM - ${t('Products')}`
  }, [t])

  useEffect(() => () => {
    // reset search text when leaving the page
    setSearchTextProducts('')
  }, [setSearchTextProducts])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <section>
          <Title results={products?.length} title="Products" add="" />
          <RenderIf isTrue={isProductsLoading}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isProductsLoading}>
            <ProductsTable
              products={products}
              searchTextProducts={searchTextProducts}
              onSearchProductsHandler={onSearchProductsHandler}
            />
          </RenderIf>
        </section>
      </RenderIf>

      <RenderIf isTrue={!isExactPath}>
        <Outlet context={products} />
      </RenderIf>
    </>

  )
}
