import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { ClientTabTypes } from '../../../../../interfaces/client'
import Table from '../../../../../components/Table/Table'

interface Props {
  clientTabItems: ClientTabTypes[]
}
function ClientTabsTable({ clientTabItems = [] }:Props) {
  const { t } = useTranslation()

  const columns = useMemo(() => [
    {
      Header: t('a_a'),
      accessor: 'a_a'
    },
    {
      Header: t('DOCTYPE'),
      accessor: 'doctype'
    },
    {
      Header: t('code'),
      accessor: 'code'
    },
    {
      Header: t('description'),
      accessor: 'name'
    },
    {
      Header: t('priceTab'),
      accessor: 'price'
    },
    {
      Header: t('discount'),
      accessor: 'discount'
    },
    {
      Header: t('value'),
      accessor: 'value'
    },
    {
      Header: t('taxValue'),
      accessor: 'tax_value'
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={clientTabItems}
      storageKey="clientTabItemsTable"
    />
  )
}

export default ClientTabsTable
