import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import moment from 'moment/moment'
import Table from '../../../../../components/Table/Table'
import { AgentCallByDate, CallStatusTypes } from '../../../../../interfaces/calls'
import { durationToTime } from '../../../../../helpers/duration-to-time'
import CallStatus from '../../../../../components/UI/CallStatus'

interface Props {
  calls: AgentCallByDate[]
}

function LastCallsByDateTable({
  calls = []
}:Props) {
  const { t } = useTranslation()

  const columns = useMemo(() => [
    {
      Header: t('clientName'),
      accessor: 'client_name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => (<p title={value} className="truncate max-w-[180px]">{value}</p>)
    },
    {
      Header: t('notes'),
      accessor: 'notes',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => <p title={value} className="line-clamp-2">{value || '-'}</p>
    },
    {
      Header: t('callOutcome'),
      accessor: 'status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => (value
        ? <CallStatus status={value as CallStatusTypes} />
        : <p>-</p>)
    },
    {
      Header: t('telephone'),
      accessor: 'client_telephone'
    },
    {
      Header: t('callAttempts'),
      accessor: 'attempts'
    },
    {
      Header: t('duration'),
      accessor: 'duration',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: number }) => <p>{durationToTime(value)}</p>
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={calls}
      storageKey="agentCallsByDateTable"
      rowsPerPage={5}
    />
  )
}

export default LastCallsByDateTable
