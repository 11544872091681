import {
  useGlobalFilter, usePagination, useTable, useSortBy
} from 'react-table'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChangeEvent } from 'react'
import { CgScrollH } from 'react-icons/cg'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import Pagination from './Pagination'
import Search from './Search'
import RenderIf from '../UI/RenderIf'
import NoDataMessage from '../UI/NoDataMessage'
import TableFiltersDropdown from './TableFiltersDropdown'
import PrintButton from '../UI/PrintButton'
import CsvButton from '../UI/CsvButton'
import { ClientOrdersDetails, ClientTypes, InfiniteActionPaginateTypes } from '../../interfaces/client'
import { SortingOrderTypes, StorageKeyTypes } from '../../interfaces/table'
import SearchFromApi from './SearchFromApi'
import { useDataProvider } from '../../context/DataProviderCtx/DataProvider'
import ViewSelectedClientsBtn from '../../pages/Shared/pages/Newsletters/components/MailingList/ViewSelectedClientsBtn'
import UserMethods from '../../utils/userMethods'
import { tableRowNavigation } from '../../helpers/tableRowNavigation'
import AgentMapModal from '../../pages/SalesAgent/components/AgentMapModal'
import MapFiltersProvider from '../../context/MapFiltersCtx/MapFiltersProvider'
import ToughClientsFilter from '../../pages/Shared/pages/Clients/components/ToughClientsFilter'
import { useModalContext } from '../../context/ModalsCtx/ModalsProvider'

interface TableProps {
  columns: any,
  data: any,
  storageKey: StorageKeyTypes,
  rowsPerPage?: number
  apiPagination?: InfiniteActionPaginateTypes
  searchText?: string
  sortText?: string
  sortingOrder?: SortingOrderTypes
  onSearchHandler?: (event: ChangeEvent<HTMLInputElement>) => void
  selectedClients?: ClientTypes[]
  onShowSelectedClientsHandler?: () => void
  onSortTextHandler?: (sortText: string) => void
  onSortingOrderHandler?: () => void
  sortingHeadersNames?: (header: string) => string | null
  isHidden?: boolean
}

export default function Table({
  data,
  columns,
  storageKey,
  rowsPerPage = 5,
  apiPagination,
  searchText,
  sortText,
  sortingOrder,
  onSearchHandler,
  selectedClients = [],
  onShowSelectedClientsHandler,
  isHidden = false,
  onSortTextHandler,
  onSortingOrderHandler,
  sortingHeadersNames
}: TableProps) {
  const authUser = UserMethods.getUser()
  const complaintCreatorColumn = authUser?.user.user_type !== 'DIRECTOR' ? 'creator' : ''
  const initialColumns = JSON.parse(localStorage.getItem(storageKey)!) ?? []
  const {
    isClientsLoading
  } = useDataProvider()
  const { openClientsTabModalHandler } = useModalContext()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isClientsTable = pathname.includes('/clients') && !pathname.includes('/client-info')
  const isMailingListTable = pathname.includes('/create-newsletter')
  const isOrdersTable = pathname.includes('/orders')
  const isCallsTable = pathname.includes('/calls')
  const isProductsTable = pathname.includes('/products')

  const isRemoteSorting = isMailingListTable
    || isClientsTable
    || isOrdersTable

  const withLocalSorting = [
    useGlobalFilter,
    useSortBy,
    usePagination
  ]
  const withRemoteSorting = [
    useGlobalFilter,
    usePagination
  ]
  const tablePlugins = isRemoteSorting ? withRemoteSorting : withLocalSorting

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    rows,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    setGlobalFilter,
    allColumns
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: storageKey === 'clientTabItemsTable' ? 500 : rowsPerPage,
        hiddenColumns: [...initialColumns, complaintCreatorColumn]
      }
    },
    ...tablePlugins
  )
  const { pageIndex, pageSize } = state;
  const disablePrint = 'print:hidden';
  const print = 'print:m-0 print:p-0 print:overflow-x-visible print:text-[10px]'
  const disableRowHoverNavigation = storageKey === 'mailingListTable'
    || storageKey === 'callsTable'
    || storageKey === 'offersTable'
    || storageKey === 'tasksTable'
    || storageKey === 'newslettersTable'
     || storageKey === 'agentCallsByDateTable'

  return (
    <div className={`${isHidden ? 'hidden' : 'block'} bg-white rounded-2xl w-full h-max ${isMailingListTable ? 'mt-0 shadow-none p-0' : 'mt-7 shadow-faint p-0 md:p-8'} `}>
      <div className={`${disablePrint}  bg-[#F8F8F8] flex justify-between items-center px-2 py-5 md:p-5 mb-8 rounded-md flex-wrap md:flex-nowrap gap-3`}>
        <RenderIf isTrue={
          !isMailingListTable
          && !isClientsTable
          && !isOrdersTable
          && !isCallsTable
          && !isProductsTable
        }
        >
          <Search setFilter={setGlobalFilter} />
        </RenderIf>
        <RenderIf isTrue={isMailingListTable
          || isClientsTable
          || isOrdersTable
          || isCallsTable
          || isProductsTable}
        >
          <div className="flex items-center w-full sm:w-[80%] gap-3 sm:gap-5 flex-wrap">
            <SearchFromApi
              searchText={searchText!}
              onChangeHandler={onSearchHandler!}
              isLoading={isClientsLoading}
            />
            <RenderIf isTrue={isMailingListTable}>
              <ViewSelectedClientsBtn
                selectedClients={selectedClients}
                onClickHandler={onShowSelectedClientsHandler!}
              />
            </RenderIf>
          </div>
        </RenderIf>

        <div className="flex gap-2 w-full md:w-auto">
          <RenderIf isTrue={isCallsTable || storageKey === 'agentCallsByDateTable'}>
            <CsvButton data={data} />
          </RenderIf>
          <PrintButton />
          <TableFiltersDropdown
            allColumns={allColumns}
            storageKey={storageKey}
          >
            <RenderIf isTrue={isClientsTable}>
              <ToughClientsFilter />
            </RenderIf>
          </TableFiltersDropdown>
          <RenderIf isTrue={isClientsTable && authUser?.user.user_type === 'AGENT'}>
            <MapFiltersProvider>
              <AgentMapModal />
            </MapFiltersProvider>
          </RenderIf>
          <CgScrollH size={35} className="ml-auto md:hidden text-grey-pale" />
        </div>
      </div>
      <div className="overflow-auto print:overflow-visible">
        <table {...getTableProps()} className={`w-full border-collapse ${isClientsTable ? 'min-w-[1400px]' : 'min-w-[1100px]'} print:min-w-[400px] print:max-w-[1100px] text-[#959292]`}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const sortingText = sortingHeadersNames?.(column.render('Header') as string) ?? null
                  const isSortableColumn = sortingText && isRemoteSorting

                  return (
                    <th
                      onClick={() => {
                        if (isSortableColumn && onSortTextHandler && onSortingOrderHandler) {
                          onSortTextHandler(sortingText)
                          onSortingOrderHandler()
                        }
                      }}
                      style={!isClientsTable ? { width: `calc(100% / ${column.width})` } : { width: 'auto' }}
                      {...column.getHeaderProps(!isRemoteSorting
                        ? column.getSortByToggleProps()
                        : undefined)}
                      className={`${isSortableColumn ? 'cursor-pointer' : 'cursor-auto'} ${isClientsTable ? 'px-2' : 'px-2 2xl:px-0'} ${print} text-left text-[#4D4D4D] text-lg font-semibold border-b border-b-grey-pale pb-5 whitespace-nowrap `}
                    >
                      <span className="flex items-center gap-2">
                        {column.render('Header')}
                        {
                        isRemoteSorting ? (
                          <span>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {sortingText === sortText ? sortingOrder === 'desc' ? <FaChevronDown className="text-btn-sub" />
                              : <FaChevronUp className="text-btn-sub" /> : ''}
                          </span>
                        )
                          : (
                            <span>
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {column.isSorted ? column.isSortedDesc ? <FaChevronDown className="text-btn-sub" />
                                : <FaChevronUp className="text-btn-sub" /> : ''}
                            </span>
                          )
}
                      </span>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr className={`${disableRowHoverNavigation ? '' : 'transition-colors duration-200 hover:bg-[rgba(0,0,0,0.05)] cursor-pointer'} border-b border-b-[#E8E8E8] last:border-b-0`} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    const disableColumnActionNumber = isClientsTable
                      ? index !== row.cells.length - 1 && index !== row.cells.length - 2
                      : index !== row.cells.length - 1
                    return (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                      <td
                        // eslint-disable-next-line no-nested-ternary
                        onClick={() => {
                          if (storageKey === 'clientOrdersDetailsTable') {
                            openClientsTabModalHandler(
                              (cell.row.original as ClientOrdersDetails).FINDOC
                            )
                            return
                          }

                          if (disableColumnActionNumber && !disableRowHoverNavigation) {
                            navigate(tableRowNavigation({
                              key: storageKey,
                              rowData: cell.row.original
                            }))
                          }
                        }}
                        {...cell.getCellProps()}
                        className={` ${isClientsTable ? 'px-2' : 'px-2 2xl:px-0'} ${print} ${rowsPerPage === 10 || isMailingListTable ? 'h-[50px]' : 'h-[80px]'} text-black`}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <RenderIf isTrue={rows.length === 0}>
        <NoDataMessage text="noEntriesFound" />
      </RenderIf>
      <RenderIf isTrue={storageKey !== 'clientTabItemsTable'}>
        <Pagination
          pageLength={page.length}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          pageSize={pageSize}
          gotoPage={gotoPage}
          apiPagination={apiPagination}
        />
      </RenderIf>
    </div>

  )
}
