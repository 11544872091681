import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import Title from '../../../../components/UI/Title'
import ClientBasicInfo from '../../../Shared/pages/Clients/components/ClientBasicInfo'
import FormLayout from '../../../../components/Form/FormLayout'
import InitializeCall from '../../components/InitializeCall/InitializeCall'
import routes from '../../../../constants/routes'
import { TodayCallsTypes } from '../../../../interfaces/calls'
import CallHistoryContainer from '../../../../components/ClientCallsHistory/CallHistoryContainer'
import LatestOrdersContainer from '../../../../components/ClientLatestOrders/LatestOrdersContainer'

function ClientCallInfo() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const [clientNumber, callId] = params.clientCall!.split('-')
  const todayCalls = useOutletContext<TodayCallsTypes[]>()

  const client = todayCalls?.find((call) => call.client.client_number === clientNumber)
  const call = todayCalls?.find((clientCall) => clientCall.id.toString() === callId)
  useEffect(() => {
    document.title = `Emoria CRM - ${t('clientCallInfo')}`
  }, [t])

  return (
    <div className="w-full">
      <div className="flex justify-between gap-5 mb-5 mt-8 flex-col-reverse md:items-center md:flex-row">
        <Title title={client?.client.full_name ?? ''} add="" marginBottom="mb-0" marginTop="mt-0" />
        <button
          type="button"
          className="font-semibold flex items-center gap-2 whitespace-nowrap w-max text-btn-sub md:rounded-[5px] bg-white px-5 py-4 border border-btn-sub"
          onClick={() => navigate(routes.agent.home)}
        >
          <BsChevronLeft size={20} />
          {t('backToControlPanel')}
        </button>
      </div>
      <div className="flex gap-2 flex-col-reverse xl:flex-row">
        <FormLayout>
          <ClientBasicInfo showInfo={false} client={client?.client} gridCols="lg:grid-cols-3" ellipsis="line-clamp-1 text-ellipsis" />
        </FormLayout>
        {call
          && (
          <div className="w-full xl:min-w-[38%] xl:max-w-[38%]">
            <InitializeCall call={call} />
          </div>
          )}
      </div>
      <div className="grid xl:grid-cols-2 gap-2">
        <LatestOrdersContainer client={client?.client} />
        <CallHistoryContainer clientNumber={clientNumber} />
      </div>
    </div>
  )
}

export default ClientCallInfo
