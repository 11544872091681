import axios, { AxiosError, AxiosResponse } from 'axios'
import axiosInstance from '../axiosInstance'
import { UsersServiceInterface } from './usersServiceInterface'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import {
  DeleteUserTypes, UpdateMeTypes, UpdateUserTypes, UserCreationTypes, UserTypes
} from '../../interfaces/user'

interface FormDataTypes {
  username: string,
  first_name: string,
  last_name: string,
  email: string,
  telephone: string
  department?: string
  password?: string
  notes?: string
}

class UsersService implements UsersServiceInterface {
  public async getUsers(): Promise<UserTypes[] | null> {
    try {
      const response = await axiosInstance.get('/auth/user/all/')
      const transformedUsers = Object.keys(response.data.data).map((key) => response.data.data[key])
      const users:UserTypes[] = []
      transformedUsers.forEach((user) => {
        users.push(...user)
      })
      return users
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }

      return null
    }
  }

  public async deleteUser({ id, userRole }: DeleteUserTypes): Promise<AxiosResponse | null> {
    try {
      const userEndpoint = userRole.toLowerCase()
      return await axiosInstance.delete(`/auth/${userEndpoint}/${id}/delete/`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createUser(values: UserCreationTypes): Promise<AxiosResponse | null> {
    try {
      const formData: FormDataTypes = {
        username: values.username.trim(),
        first_name: values.firstName.trim(),
        last_name: values.lastName.trim(),
        email: values.email.trim(),
        telephone: values.phone.trim(),
        department: values.department.trim(),
        notes: ''
      }

      const userEndpoint = values.role.toLowerCase()

      return await axiosInstance.post(`/auth/${userEndpoint}/`, formData)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateUser({ id, values }:UpdateUserTypes): Promise<AxiosResponse | null> {
    try {
      const formData: FormDataTypes = {
        username: values.username.trim(),
        first_name: values.firstName.trim(),
        last_name: values.lastName.trim(),
        email: values.email.trim(),
        telephone: values.phone.trim()
      }

      if (values.role === 'AGENT') {
        delete formData.department
      }
      const userEndpoint = values.role.toLowerCase()

      return await axiosInstance.patch(`/auth/${userEndpoint}/${id}/`, formData)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateMe(values: UpdateMeTypes): Promise<AxiosResponse | null> {
    const formData = {
      username: values.username.trim(),
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      telephone: values.phone.trim(),
      email: values.email.trim(),
      password: values.password?.trim()
    }

    if (values.password?.length === 0) {
      delete formData.password
    }

    try {
      const response = await axiosInstance.patch(`/auth/${values.role.toLowerCase()}/${values.id}/`, formData)
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async getManagers():Promise<UserTypes[] | null> {
    try {
      const response = await axiosInstance.get('/auth/manager/')
      return response.data.data as UserTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getAgents():Promise<UserTypes[] | null> {
    try {
      const response = await axiosInstance.get('/auth/agent/')
      return response.data.data as UserTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default UsersService;
