import {
  useCallback, useState
} from 'react'
import { SortingOrderTypes } from '../interfaces/table'

const useTableSorting = () => {
  const [sortText, setSortText] = useState<string>('')
  const [sortingOrder, setSortingOrder] = useState<SortingOrderTypes>('initial')
  const apiSortingOrder = !(sortingOrder === 'initial' || sortingOrder === 'desc')

  const onSortingOrderHandler = useCallback(() => {
    if (sortingOrder === 'initial') {
      setSortingOrder('asc')
    }
    if (sortingOrder === 'asc') {
      setSortingOrder('desc')
    }
    if (sortingOrder === 'desc') {
      setSortText('')
      setSortingOrder('initial')
    }
  }, [sortingOrder, setSortText, setSortingOrder])

  const onSortTextHandler = useCallback((sortName: string) => {
    setSortText(sortName)
  }, [])

  return {
    sortText,
    sortingOrder,
    setSortText,
    setSortingOrder,
    onSortingOrderHandler,
    onSortTextHandler,
    apiSortingOrder
  }
}

export default useTableSorting;
