import {
  useMemo, useContext, useState, useEffect, useCallback, FormEvent
} from 'react'
import { ChildrenTypes } from '../../interfaces/children'
import { useDataProvider } from '../DataProviderCtx/DataProvider'
import { MapAgentTypes } from '../../interfaces/user'
import { ClientPinTypes, ClientsCitiesTypes, SearchedClientPinTypes } from '../../interfaces/client'
import useGetData from '../../hooks/useGetData'
import apiService from '../../services/api/apiService'
import { MapFiltersCtxTypes } from './mapFiltersCtx.interface'
import mapFiltersCtx from './mapFiltersCtx'

export const useMapFilters = (): MapFiltersCtxTypes => useContext(mapFiltersCtx)

function MapFiltersProvider({ children }: ChildrenTypes) {
  const { agents } = useDataProvider()
  const [showAgentMapModal, setShowAgentMapModal] = useState<boolean>(false)
  const [mapAgents, setMapAgents] = useState<MapAgentTypes[]>([])
  const [clientsCities, setClientsCities] = useState<ClientsCitiesTypes[]>([])
  const [selectedClientsPins, setSelectedClientsPins] = useState<ClientPinTypes[]>([])
  const [showSelectedPins, setShowSelectedPins] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchedClientsPins, setSearchedClientsPins] = useState<SearchedClientPinTypes[]>([])
  const [selectAllAgentOptions, setSelectAllAgentOptions] = useState<boolean>(false)

  const [totalValues, setTotalValues] = useState<{
    clients: number,
    totalOrders: number,
    totalOrdersSum: number
  }>({ clients: 0, totalOrders: 0, totalOrdersSum: 0 })

  const localStorageMapPins = JSON.parse(localStorage.getItem('mapPins') ?? '[]')
  const mapPinsAddedTime = localStorage.getItem('mapPinsAddedTime')

  if (!mapPinsAddedTime && localStorageMapPins.length > 0) {
    localStorage.setItem('mapPinsAddedTime', new Date().toString())
  }

  const { data: cities } = useGetData<string[]>({
    queryKey: 'clientsCities',
    queryFn: apiService.getClientsCities,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  const { data, isLoading: isMapPinsLoading } = useGetData<ClientPinTypes[]>({
    queryKey: 'mapPins',
    queryFn: apiService.getClientsPins,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    disabled: localStorageMapPins.length > 0
  })

  const mapPins: ClientPinTypes[] = localStorageMapPins.length === 0
    ? data
    : localStorageMapPins

  if (localStorageMapPins.length === 0) {
    localStorage.setItem('mapPins', JSON.stringify(data ?? []))
  }

  // Rest all filters
  const onClearPins = useCallback(() => {
    setShowSelectedPins(false)
    setSelectAllAgentOptions(false)
    setSelectedClientsPins([])
    setSearchedClientsPins([])
    setSearchValue('')
    setMapAgents((prevState) => prevState.map((agent) => ({ ...agent, isSelected: false })))
    setClientsCities((prevState) => prevState.map((city) => ({ ...city, isSelected: false })))
    setTotalValues({ clients: 0, totalOrders: 0, totalOrdersSum: 0 })
  }, [])

  const onShowPins = useCallback(() => {
    // Get selected agents if isSelected is true
    const selectedAgents = mapAgents.filter((agent) => agent.isSelected)
    // Get selected cities if isSelected is true
    const selectedCities = clientsCities.filter((city) => city.isSelected)
    // Return if no agents are selected or no searched pins are selected
    if (selectedAgents.length === 0
      && searchedClientsPins.length === 0
      && selectedClientsPins.length === 0
      && selectedCities.length === 0) {
      return
    }
    // Get clients pins from selected agents department
    const filterClientsPins = mapPins
      .filter((pin) => selectedAgents
        .some((agent) => `${agent.user.first_name} ${agent.user.last_name}` === `${pin.agent.user.first_name} ${pin.agent.user.last_name}`))
    // Get individual clients pins if isSelected is true also don't add if already exist
    const filterSearchedClientsPins = searchedClientsPins.filter((pin) => pin.isSelected
      && !filterClientsPins.some((marker) => marker.client_number === pin.client_number))
    // Get clients pins from selected cities
    const filterClientsCitiesPins = mapPins
      .filter((pin) => selectedCities
        .some((city) => `${city.city}` === `${pin.city}`))

    // Merge both arrays
    setSelectedClientsPins([
      ...filterClientsPins,
      ...filterSearchedClientsPins,
      ...filterClientsCitiesPins
    ])
    setSearchedClientsPins([])
    setSearchValue('')
    setShowSelectedPins(true)
  }, [mapPins, mapAgents, clientsCities, searchedClientsPins, selectedClientsPins])

  const onSelectAllAgentOptionsHandler = useCallback(
    () => setSelectAllAgentOptions((prevState) => !prevState),
    []
  )

  const onMapAgentChangeHandler = useCallback((option: MapAgentTypes) => {
    setMapAgents((prevState) => prevState
      .map((state) => (state.id === option.id
        ? {
          ...state,
          isSelected: !state.isSelected
        } : state
      )))
  }, [])

  const onCitiesChangeHandler = useCallback((option: ClientsCitiesTypes) => {
    setClientsCities((prevState) => prevState
      .map((state) => (state.city === option.city
        ? {
          ...state,
          isSelected: !state.isSelected
        } : state
      )))
  }, [])

  const onSearchValueChangeHandler = useCallback((event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setSearchValue(value)
    if (value.length > 2) {
      const searchResults = mapPins.filter((pin) => pin.full_name
        .toLowerCase().includes(value.toLowerCase())
        || pin.vat_number.toLowerCase()
          .startsWith(value.toLowerCase())) as SearchedClientPinTypes[]
      setSearchedClientsPins(searchResults)
    } else {
      setSearchedClientsPins([])
    }
  }, [mapPins])

  const onSelectedResultChangeHandler = useCallback((option: SearchedClientPinTypes) => {
    setSearchedClientsPins((prevState) => prevState
      .map((state) => (state.client_number === option.client_number
        ? {
          ...state,
          isSelected: !state.isSelected
        } : state
      )))
  }, [])

  // Clear map pins from local storage after 12 hours of adding
  useEffect(() => {
    const addedTime = localStorage.getItem('mapPinsAddedTime')
    if (addedTime) {
      const timeDiff = new Date().getTime() - new Date(addedTime).getTime()
      const hoursDiff = timeDiff / (1000 * 3600)
      if (hoursDiff > 12) {
        localStorage.removeItem('mapPins')
        localStorage.removeItem('mapPinsAddedTime')
      }
    }
  }, [])

  // Get agents for map filters and insert isSelected property
  useEffect(() => {
    if (agents) {
      const transformedAgents: MapAgentTypes[] = agents?.map((agent) => ({
        ...agent,
        isSelected: false
      }))
      setMapAgents(transformedAgents)
    }
  }, [agents])

  // Get cities insert isSelected property
  useEffect(() => {
    if (cities) {
      cities.sort()
      const transformedCities: ClientsCitiesTypes[] = cities?.map((city) => ({
        city,
        isSelected: false
      }))
      setClientsCities(transformedCities)
    }
  }, [cities])

  // Get total values for selected clients
  useEffect(() => {
    setTotalValues({
      clients: selectedClientsPins.length,
      totalOrders: selectedClientsPins
        .reduce((acc, curr) => acc + curr.total_orders, 0),
      totalOrdersSum: selectedClientsPins
        .reduce((acc, curr) => +acc + +curr.total_orders_amount, 0)
    })
  }, [selectedClientsPins])

  // Select or Unselect all agents
  useEffect(() => {
    if (selectAllAgentOptions) {
      setMapAgents((prevState) => prevState.map((agent) => ({ ...agent, isSelected: true })))
    }
    if (!selectAllAgentOptions) {
      setMapAgents((prevState) => prevState.map((agent) => ({ ...agent, isSelected: false })))
    }
  }, [selectAllAgentOptions])

  const ctx = useMemo(() => ({
    searchValue,
    showAgentMapModal,
    mapAgents,
    clientsCities,
    selectedClientsPins,
    searchedClientsPins,
    totalValues,
    isMapPinsLoading,
    showSelectedPins,
    selectAllAgentOptions,
    onClearPins,
    onShowPins,
    onMapAgentChangeHandler,
    onCitiesChangeHandler,
    onSearchValueChangeHandler,
    onSelectedResultChangeHandler,
    onSelectAllAgentOptionsHandler,
    setShowAgentMapModal
  }), [
    searchValue,
    showAgentMapModal,
    mapAgents,
    clientsCities,
    selectedClientsPins,
    searchedClientsPins,
    totalValues,
    isMapPinsLoading,
    showSelectedPins,
    selectAllAgentOptions,
    onClearPins,
    onShowPins,
    onMapAgentChangeHandler,
    onCitiesChangeHandler,
    onSearchValueChangeHandler,
    onSelectedResultChangeHandler,
    onSelectAllAgentOptionsHandler,
    setShowAgentMapModal
  ]);
  return (
    <mapFiltersCtx.Provider value={ctx}>
      {children}
    </mapFiltersCtx.Provider>
  )
}

export default MapFiltersProvider
