import React from 'react'
import { BsCardList } from 'react-icons/bs'
import CardOverview from '../../../components/UI/CardOverview'
import { TodayOrdersTypes } from '../../../interfaces/statistics'

interface Props {
  todayOrders: TodayOrdersTypes
}
function TodayOrders({ todayOrders }:Props) {
  return (
    <CardOverview text="ordersToday" number={todayOrders?.total?.orders_count ?? 0} secondaryNumber={`/${todayOrders?.total?.orders_sum?.toFixed(2) ?? 0}€`}>
      <BsCardList color="#959292" size={30} />
    </CardOverview>
  )
}

export default TodayOrders
