import { useTranslation } from 'react-i18next'
import FormLayout from '../../../../../components/Form/FormLayout'
import { ClientOrdersRemainingTotal, ClientTypes } from '../../../../../interfaces/client'
import ClientBasicInfo from './ClientBasicInfo'
import useGetData from '../../../../../hooks/useGetData'
import apiService from '../../../../../services/api/apiService'

interface Props {
  client?: ClientTypes
}

function ClientBasicDetails({ client }: Props) {
  const { t } = useTranslation()

  const { data: clientOrdersRemainingTotal } = useGetData<ClientOrdersRemainingTotal>({
    queryKey: `clientOrdersRemainingTotal ${client?.client_number}`,
    queryFn: () => apiService
      .getClientOrdersRemainingTotal({ clientNumber: client?.client_number! })
  })

  return (
    <FormLayout>
      <div className="w-full">
        <h2 className="font-semibold text-xl text-primary-text-dark mb-8">{t('basicDetails')}</h2>
        <ClientBasicInfo client={client} clientOrdersRemainingTotal={clientOrdersRemainingTotal} gridCols="lg:grid-cols-3" />
      </div>
    </FormLayout>
  )
}

export default ClientBasicDetails
