import axios, { AxiosError, AxiosResponse } from 'axios'
import { ClientsServiceInterface } from './clientsService.interface'
import axiosInstance from '../axiosInstance'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'

import {
  ClientCountryTypes,
  ClientCreationTypes,
  ClientJobCategoryTypes,
  ClientPaginateTypes,
  ClientPinTypes,
  ClientTypes,
  ClientLatestOrderTypes,
  ClientCallHistoryTypes,
  ClientLatestCallTypes,
  ToughClientTypes,
  ClientCommentsTypes,
  ClientOrdersRemainingTotal,
  ClientOrdersDetails,
  ClientTabTypes
} from '../../interfaces/client'

class ClientsService implements ClientsServiceInterface {
  public async getClients({
    pageParam = 1,
    searchText = '',
    sortText = '',
    ascending = false,
    isTough = false
  }): Promise<ClientPaginateTypes | null> {
    try {
      const response = await axiosInstance.post(`/clients/client/search/?page=${pageParam}`, {
        search_text: searchText.trim(),
        sort_text: sortText.trim(),
        ascending,
        isTough
      })
      return response.data.data as ClientPaginateTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientOrdersRemainingTotal({ clientNumber }: { clientNumber:string }):
  Promise<ClientOrdersRemainingTotal | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/details/`)
      return response.data.data as ClientOrdersRemainingTotal
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientsTabItems(finDoc:string): Promise<ClientTabTypes[] | null> {
    try {
      const response = await axiosInstance.post('/clients/client/tab-items/', {
        findoc: finDoc
      })
      return response.data.data.data as ClientTabTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientOrdersDetails({ clientNumber }: { clientNumber:string }):
  Promise<ClientOrdersDetails[] | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/tab/`)
      return response.data.data.data as ClientOrdersDetails[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientByClientNumber(clientNumber: string): Promise<ClientTypes | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/`)
      return response.data.data as ClientTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientCategories(): Promise<ClientJobCategoryTypes[] | null> {
    try {
      const response = await axiosInstance.get('/clients/client/job-categories/')
      return response.data.data as ClientJobCategoryTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientsPins(): Promise<ClientPinTypes[] | null> {
    try {
      const response = await axiosInstance.get('/clients/client/pins/')
      return response.data.data as ClientPinTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientCountries(): Promise<ClientCountryTypes[] | null> {
    try {
      const response = await axiosInstance.get('/clients/client/countries/')
      return response.data.data as ClientCountryTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientsLatestOrders(clientNumber: string):
  Promise<ClientLatestOrderTypes[] | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/last-orders/?q=4`)
      return response.data.data as ClientLatestOrderTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientsLatestCalls(agent: number):
  Promise<ClientLatestCallTypes[] | null> {
    try {
      const response = await axiosInstance.get(`/auth/agent/${agent}/calls/?q=5`)
      return response.data.data as ClientLatestCallTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientsCallHistory(clientNumber: string):
  Promise<ClientCallHistoryTypes[] | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/calls/`)
      return response.data.data as ClientCallHistoryTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientComments(clientNumber: string):
  Promise<ClientCommentsTypes[] | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/comments/`)
      return response.data.data.data as ClientCommentsTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientsCities():
  Promise<string[] | null> {
    try {
      const response = await axiosInstance.get('/clients/client/city/')
      return response.data.data.data as string[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createClientComment({ clientNumber, comment }:
  { clientNumber:string, comment:string }): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.post(`/clients/client/${clientNumber}/create-comment/`, {
        comment
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createClient(values: ClientCreationTypes): Promise<AxiosResponse | null> {
    try {
      const formData = new FormData()
      formData.append('code1', values.code1.trim())
      formData.append('vat_number', values.vatNumber.trim())
      formData.append('irs_branch', values.irsBranch.trim())
      formData.append('full_name', values.fullName.trim())
      formData.append('category', values.category.trim())
      formData.append('tax_exemption', values.taxExemption.trim())
      formData.append('telephone', values.telephone.trim())
      formData.append('email', values.email.trim())
      formData.append('county', values.county.trim())
      formData.append('city', values.city.trim())
      formData.append('area', values.area.trim())
      formData.append('zip', values.zipCode.trim())
      formData.append('address', values.address.trim())
      formData.append('last_contact', values.lastContact.trim())
      formData.append('agent', values.agent.toString().trim())

      if (values.clientAgreement !== '') {
        formData.append('client_agreement', values.clientAgreement)
      }

      return await axiosInstance.post('/clients/client/', formData)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateClient(values: ClientCreationTypes): Promise<AxiosResponse | null> {
    try {
      const formData = new FormData()
      // formData.append('vat_number', values.vatNumber.trim())
      // formData.append('full_name', values.fullName.trim())
      // formData.append('irs_branch', values.irsBranch.trim())
      // formData.append('tax_exemption', values.taxExemption.trim())
      // formData.append('category', values.category.trim())
      // formData.append('telephone', values.telephone.trim())
      // formData.append('email', values.email.trim())
      // formData.append('city', values.city.trim())
      // formData.append('county', values.county.trim())
      // formData.append('zip', values.zipCode.trim())
      // formData.append('address', values.address.trim())
      // formData.append('area', values.area.trim())
      // formData.append('last_contact', values.lastContact.trim())
      // formData.append('agent', values.agent.toString().trim())

      if (values.clientAgreement !== '') {
        formData.append('client_agreement', values.clientAgreement)
      }

      return await axiosInstance.patch(`/clients/client/${values.clientNumber}/`, formData)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateClientCallFrequency({ clientNumber, frequency }:
  { clientNumber:string, frequency:number }): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.patch(`/clients/client/${clientNumber}/frequency/`, { frequency })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateToughClient(
    { clientNumber, isTough }:ToughClientTypes
  ): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.patch(`/clients/client/${clientNumber}/`, { tough_client: isTough })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteClient({ id: vat }: { id: string }): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.delete(`/clients/client/${vat}/`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteTradeAgreementFile(
    { id }: { id: string }
  ): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.delete(`/clients/client/${id}/delete-file/`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteClientComment(id: number): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.delete('/clients/client/delete-comment/', {
        data: {
          comment_id: id
        }
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default ClientsService;
