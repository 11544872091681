import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Title from '../../../../components/UI/Title'
import ClientsTable from './components/ClientsTable'
import routes from '../../../../constants/routes'
import RenderIf from '../../../../components/UI/RenderIf'
import apiService from '../../../../services/api/apiService'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import { useDataProvider } from '../../../../context/DataProviderCtx/DataProvider'
import useGetData from '../../../../hooks/useGetData'
import { ClientCountryTypes, ClientJobCategoryTypes } from '../../../../interfaces/client'

export default function Clients() {
  const { t } = useTranslation()
  const {
    clients,
    isClientsLoading,
    agents,
    clientPaginate,
    setSearchTextClient,
    setShowToughClients,
    setSortingOrderClient,
    setSortTextClient,
    clientsCount
  } = useDataProvider()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.agent.clients
    || pathname === routes.director.clients

  const { data: clientCategories } = useGetData<ClientJobCategoryTypes[]>({
    queryKey: 'clientCategories',
    queryFn: apiService.getClientCategories
  })

  const { data: clientCountries } = useGetData<ClientCountryTypes[]>({
    queryKey: 'clientCountries',
    queryFn: apiService.getClientCountries
  })

  useEffect(() => {
    document.title = `Emoria CRM - ${t('clients')}`
  }, [t])

  useEffect(() => () => {
    // reset table state when leaving the page
    setSearchTextClient('')
    setShowToughClients(false)
    setSortingOrderClient('initial')
    setSortTextClient('')
  }, [setSearchTextClient, setShowToughClients, setSortingOrderClient, setSortTextClient])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <section>
          <Title results={clientsCount} title="clients" add="Add Client" onClickHandler={() => navigate(routes.shared.createClient)} />
          <RenderIf isTrue={isClientsLoading || clientPaginate.isFetchingNextPage}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isClientsLoading}>
            <ClientsTable clients={clients} isHidden={clientPaginate.isFetchingNextPage} />
          </RenderIf>
        </section>
      </RenderIf>

      <RenderIf isTrue={!isExactPath}>
        <Outlet context={{
          agents, clients, clientCategories, clientCountries
        }}
        />
      </RenderIf>
    </>
  )
}
