import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/UI/PageTitle'
import DirectorCardsContainer from './components/DirectorCardsContainer'
import NumberOfOrdersChart from '../../components/Charts/NumberOfOrdersChart'
import ClientsMap from '../Shared/components/Map/ClientsMap'
import MapFiltersProvider from '../../context/MapFiltersCtx/MapFiltersProvider'
import ControlPanelTasksContainer from '../Shared/pages/Tasks/components/ControlPanelTasksContainer'

function Director() {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `Emoria CRM - ${t('Director')}`
  }, [t])

  return (
    <section className="mt-8">
      <PageTitle text="directorControlPanel" />
      <DirectorCardsContainer />
      <div className="grid grid-cols-1 2xl:grid-cols-2 gap-8">
        <div className="flex flex-col gap-8">
          <ControlPanelTasksContainer />
          <NumberOfOrdersChart text="numberOfOrders" />
        </div>
        <MapFiltersProvider>
          <ClientsMap />
        </MapFiltersProvider>
      </div>
    </section>
  )
}

export default Director
