import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import PageTitle from '../../../../../components/UI/PageTitle'
import OrderCreationForm from '../components/OrderCreationForm'
import { OrderCreationTypes } from '../../../../../interfaces/orders'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import AddProductsToOrder from '../components/AddProductsToOrder'
import FormButtons from '../../../../../components/Form/FormButtons'
import { useDataProvider } from '../../../../../context/DataProviderCtx/DataProvider'
import { useProductsCart } from '../../../../../context/ProductsCartCtx/ProductsCartProvider'
import validationSchema from '../../../../../utils/validationSchema'
import useGetData from '../../../../../hooks/useGetData'
import { ClientTypes } from '../../../../../interfaces/client'
import RenderIf from '../../../../../components/UI/RenderIf'
import OrderInfoSkeleton from '../../../../../components/Skeletons/OrderInfoSkeleton'

function CreateOrder() {
  const { t } = useTranslation()
  const { products } = useDataProvider()
  const {
    totalPrice, taxPrice, selectedProducts, setSelectedProducts
  } = useProductsCart()
  const navigate = useNavigate()
  const params = useParams()

  const { data: client, isLoading: isClientLoading } = useGetData<ClientTypes>({
    queryKey: `client-${params.clientNumber}`,
    queryFn: () => apiService.getClientByClientNumber(params.clientNumber!),
    disabled: !params.clientNumber
  })

  // Create order
  const { mutate: createOrder, isLoading } = useMutateData({
    key: 'orders',
    mutationFn: apiService.createOrder,
    successMessage: 'orderHasBeenCreated'
  })

  const onSubmit = (values: OrderCreationTypes) => {
    // Check if there are any products in the cart
    if (selectedProducts.length === 0) {
      toast.warn<string>(t('selectProductsInTheOrder'))
      return
    }

    // Check if item is out of stock
    if (selectedProducts.some((prd) => prd.quantity <= 0)) {
      toast.warn<string>(t('someOfTheSelectedProductsAreOutOfStock'))
      return
    }

    createOrder({ values, products: selectedProducts, totalPrice: totalPrice + taxPrice }, {
      onSuccess: () => {
        navigate(-1)
        setSelectedProducts([])
      }
    })
  }

  // Prevent form submission on Enter
  const keyDownHandler = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const isTextArea = e.target instanceof HTMLTextAreaElement;
    if (e.key === 'Enter' && !e.shiftKey && !isTextArea) {
      e.preventDefault();
    }
  };

  const initialValues: OrderCreationTypes = {
    clientInfo: client ? `${client.full_name} - ${client.vat_number}` : '',
    client: client?.client_number ?? '',
    clientAddress: client?.address ?? '',
    fincode: '',
    paymentMethod: '',
    notes: '',
    series: '',
    shipment: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.orderCreationForm,
    onSubmit
  })

  const { setFieldValue } = formik
  useEffect(() => {
    if (client) {
      setFieldValue('clientInfo', `${client.full_name} - ${client.vat_number}`)
      setFieldValue('client', client.client_number)
      setFieldValue('clientAddress', client.address)
    }
  }, [client, setFieldValue])

  useEffect(() => {
    document.title = `Emoria CRM - ${t('Add Order')}`
  }, [t])

  return (
    <section className="w-full mt-8">
      <PageTitle text={t('Add Order')} />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={formik.handleSubmit} onKeyDown={keyDownHandler}>
        <RenderIf isTrue={!isClientLoading}>
          <OrderCreationForm formik={formik} client={client} />
          <AddProductsToOrder
            products={products}
          />
          <FormButtons isLoading={isLoading} btnText="Submit Orders" />
        </RenderIf>
        <RenderIf isTrue={isClientLoading}>
          <OrderInfoSkeleton />
        </RenderIf>
      </form>
    </section>
  )
}

export default CreateOrder
