import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Title from '../../../../components/UI/Title'
import RenderIf from '../../../../components/UI/RenderIf'
import routes from '../../../../constants/routes'
import ComplaintsTable from './components/ComplaintsTable'
import useGetData from '../../../../hooks/useGetData'
import { ComplaintTypes } from '../../../../interfaces/complaints'
import apiService from '../../../../services/api/apiService'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal'

export default function Offers() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.agent.complaints
    || pathname === routes.director.complaints
  const { isLoading, data: complaints } = useGetData<ComplaintTypes[]>({
    queryKey: 'complaints',
    queryFn: apiService.getComplaints
  })

  const [t] = useTranslation()

  useEffect(() => {
    document.title = `Emoria CRM - ${t('complaints')}`
  }, [t])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <Title results={complaints?.length} title="complaints" add="Add Complaint" onClickHandler={() => navigate(routes.shared.createComplaint)} />
        <RenderIf isTrue={isLoading}>
          <TableSkeleton />
        </RenderIf>
        <RenderIf isTrue={!isLoading}>
          <ComplaintsTable complaints={complaints} />
          <ConfirmationModal cacheKey="complaints" toastSuccessMessage="complaintHasBeenDeleted" mutationFn={apiService.deleteComplaint} />
        </RenderIf>
      </RenderIf>

      <RenderIf isTrue={!isExactPath}>
        <Outlet context={complaints} />
      </RenderIf>
    </>
  )
}
