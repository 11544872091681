import axios, { AxiosError } from 'axios'
import { ProductsServiceInterface } from './productsService.interface'
import { ProductTypes } from '../../interfaces/products'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import axiosInstance from '../axiosInstance'

class ProductsService implements ProductsServiceInterface {
  public async getProducts(searchText: string): Promise<ProductTypes[] | null> {
    try {
      const response = await axiosInstance.post('/products/product/search/?page=1', {
        search_text: searchText.trim()
      })
      return response.data.data.results as ProductTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default ProductsService
