import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  width?: string
}

function AddProductHeaderValue({ text, width }: Props) {
  const { t } = useTranslation()
  const print = 'print:m-0 print:p-0'
  return (
    <p className={`last:text-right text-primary-text-dark font-semibold text-[17px] w-full ${width} ${print}`}>
      {t(text)}
    </p>
  )
}

export default AddProductHeaderValue
