const translationEl = {
  // Navigation menu links
  home: 'Αρχική',
  tickets: 'Tickets',
  clients: 'Πελάτες',
  products: 'Προϊόντα',
  analytics: 'Στατιστικά',
  statistics: 'Στατιστικά',
  agents: 'Υπάλληλοι',
  agent: 'Υπάλληλος',
  orders: 'Παραγγελίες',
  offers: 'Προσφορές',
  calls: 'Κλήσεις',
  'Email Address': 'Διεύθυνση Email',
  Username: 'Όνομα Χρήστη',
  Password: 'Κωδικός',
  'Remember me': 'Να με θυμάσαι',
  'Forgot password?': 'Ξεχάσατε τον κωδικό σας;',
  Login: 'Είσοδος',
  Welcome: 'Καλώς ήρθατε',
  Clientele: 'Πελατολόγιο',
  'Add Client': 'Προσθήκη Πελάτη',
  Products: 'Προϊόντα',
  'Add Product': 'Προσθήκη Προϊόντος',
  Orders: 'Παραγγελίες',
  'Add Order': 'Προσθήκη Παραγγελίας',
  'Add Offer': 'Προσθήκη Προσφοράς',
  settings: 'Ρυθμίσεις',
  logout: 'Αποσύνδεση',
  'New Employee': 'Νέος Υπάλληλος',
  'New Client': 'Νέος Πελάτης',
  'New Offer': 'Νέα Προσφορά',
  'Update Offer': 'Ενημέρωση Προσφοράς',
  'Update Employee': 'Ενημέρωση Υπαλλήλου',
  'Update Client': 'Ενημέρωση Πελάτη',
  'Employee Name': 'Όνομα Υπαλλήλου',
  'Employee Surname': 'Επώνυμο Υπαλλήλου',
  Email: 'E-mail',
  Phone: 'Τηλέφωνο',
  Role: 'Ρόλος',
  Agent: 'Πωλητής',
  Manager: 'Διαχειριστής',
  Managers: 'Διαχειριστές',
  Director: 'Διευθυντής',
  'Add Employee': 'Προσθήκη Υπαλλήλου',
  Cancel: 'Ακύρωση',
  'Username required.': 'Απαιτείται όνομα χρήστη.',
  'Password required.': 'Απαιτείται κωδικός.',
  'Employee name required.': 'Απαιτείται όνομα υπαλλήλου.',
  'Employee surname required.': 'Απαιτείται επώνυμο υπαλλήλου.',
  'Invalid email.': 'Το email αυτό δεν είναι έγκυρο.',
  'Email required.': 'Απαιτείται email.',
  'Invalid phone number.': 'Μη έγκυρος αριθμός τηλεφώνου.',
  'Phone number required.': 'Απαιτείται αριθμός τηλεφώνου.',
  'Role required.': 'Απαιτείται ρόλος.',
  'Full name required.': 'Απαιτείται πλήρες όνομα.',
  'Address required.': 'Απαιτείται διεύθυνση.',
  'County required.': 'Απαιτείται Νομός.',
  'Area required.': 'Απαιτείται Περιοχή.',
  'Post code required.': 'Απαιτείται Τ.Κ.',
  'Company name required.': 'Απαιτείται όνομα εταιρείας.',
  'Category required.': 'Απαιτείται κατηγορία.',
  'Offer name required.': 'Απαιτείται όνομα προσφοράς.',
  'Manager required.': 'Απαιτείται διαχειριστής.',
  'Start date required.': 'Απαιτείται ημερομηνία έναρξης.',
  'End date required.': 'Απαιτείται ημερομηνία λήξης.',
  'Discount percentage required.': 'Απαιτείται ποσοστό έκπτωσης.',
  'Status required.': 'Απαιτείται κατάσταση.',
  'Usage required.': 'Απαιτείται χρήση.',
  'Sales agent required.': 'Απαιτείται πωλητής.',
  'Vat number required.': 'Απαιτείται ΑΦΜ.',
  'Irs branch required.': 'Απαιτείται ΔΟΥ.',
  'Last contact required.': 'Απαιτείται τελευταία επαφή.',
  'Add Complaint': 'Προσθήκη Παραπόνου',
  'New Complaint': 'Νέο Παράπονο',
  'Zip code required.': 'Απαιτείται Τ.Κ.',
  'Tax exemption required.': 'Απαιτείται Κατάσταση ΦΠΑ.',
  addAgent: 'Προσθήκη Υπαλλήλου',
  agentName: 'Όνομα Υπαλλήλου',
  agentSurname: 'Επώνυμο Υπαλλήλου',
  role: 'Ρόλος',
  telephone: 'Τηλέφωνο',
  address: 'Διεύθυνση',
  postCode: 'Τ.Κ.',
  of: 'από',
  page: 'σελίδα',
  pages: 'σελίδες',
  thePageYouAreOn: 'Η σελίδα που βρίσκεστε',
  AGENT: 'Πωλητής',
  MANAGER: 'Διαχειριστής',
  DIRECTOR: 'Διευθυντής',
  search: 'Αναζήτηση',
  yesIamSure: 'Ναι, είμαι σίγουρος',
  noCancel: 'Όχι, ακύρωση',
  deleteUserMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον χρήστη;',
  deleteOfferMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την προσφορά;',
  deleteClientMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον πελάτη;',
  'Confirm password': 'Επιβεβαίωση κωδικού',
  passwordMustBe: 'Ο κωδικός πρέπει να είναι τουλάχιστον 8 χαρακτήρες και να περιέχει τουλάχιστον ένα κεφαλαίο γράμμα και έναν αριθμό.',
  passwordsMustMatch: 'Οι κωδικοί δεν ταιριάζουν.',
  requiredConfirmation: 'Απαιτείται επιβεβαίωση κωδικού.',
  clientNumber: 'Αριθμός πελ.',
  fullName: 'Πλήρες Όνομα',
  category: 'Κατηγορία',
  company: 'Εταιρεία',
  location: 'Τοποθεσία',
  lastContact: 'Τελευταία Επαφή',
  offerName: 'Όνομα Προσφοράς',
  startDate: 'Ημ. Έναρξης',
  endDate: 'Ημ. Λήξης',
  state: 'Κατάσταση',
  usage: 'Χρήση',
  discountPercentage: 'Ποσοστό Έκπτωσης',
  chooseManager: 'Επιλέξτε διαχειριστή',
  Status: 'Κατάσταση',
  ENABLED: 'Ενεργή',
  DISABLED: 'Ανενεργή',
  chooseCategory: 'Επιλέξτε κατηγορία',
  saleAgent: 'Πωλητής',
  saleAgents: 'Πωλητές',
  chooseSaleAgent: 'Επιλέξτε πωλητή',
  complaints: 'Παράπονα',
  clientName: 'Όνομα Πελάτη',
  priority: 'Προτεραιότητα',
  answer: 'Απάντηση',
  callAttempts: 'Απόπειρες Κλήσης',
  orderNumber: 'Αριθμός Παραγγελίας',
  shortDescription: 'Σύντομη Περιγραφή',
  notes: 'Σημειώσεις',
  ticketStatus: 'Κατάσταση Παραπόνου',
  submissionMedium: 'Μέσο Υποβολής',
  complaintNumber: 'Αριθμός',
  description: 'Περιγραφή',
  date: 'Ημερομηνία',
  dateCreated: 'Ημ. Υποβολής',
  client: 'Πελάτης',
  editClient: 'Επεξεργασία Πελάτη',
  action: 'Ενέργεια',
  complaintPriority: 'Προτ/ότητα',
  skuProduct: 'SKU',
  image: 'Εικόνα',
  name: 'Όνομα',
  type: 'Τύπος',
  price: 'Τιμή/τμχ',
  stock: 'Απόθεμα',
  orderNum: 'Αριθμός',
  orderDate: 'Ημερομηνία',
  payment: 'Πληρωμή',
  orderState: 'Κατάσταση',
  sendState: 'Κατ. Αποστολής',
  fulfillment: 'Εκπλήρωση',
  paid: 'Πληρώθηκε',
  notPaid: 'Δεν Πληρώθηκε',
  pending: 'Σε εκκρεμότητα',
  sent: 'Απεστάλη',
  delivered: 'Παραδόθηκε',
  onGoing: 'Σε Εξέλιξη',
  cancelled: 'Ακυρωμένη',
  completed: 'Εκτελεσμένη',
  waiting: 'Σε Αναμονή',
  partial: 'Μερική',
  complete: 'Πλήρης',
  pending2: 'Εκκρεμής',
  clientAddress: 'Διεύθυνση Πελάτη',
  choosePaymentMethod: 'Επιλέξτε τρόπο πληρωμής',
  paymentMethod: 'Μέθοδος Πληρωμής',
  cheque: 'Επιταγή',
  cashOnDelivery: 'Αντικαταβολή μετρητοίς',
  bankPrepayment: 'Προπληρωμή μέσω τραπέζης',
  bankPayment: 'Πληρωμή μέσω τραπέζης',
  'Submit Orders': 'Υποβολή Παραγγελίας',
  customerInfo: 'Στοιχεία Πελάτη',
  deliveryInfo: 'Στοιχεία Αποστολής',
  'Client selection required.': 'Απαιτείται επιλογή πελάτη.',
  'Shipping address required.': 'Απαιτείται διεύθυνση αποστολής.',
  'Payment method required.': 'Απαιτείται μέθοδος πληρωμής.',
  chooseClient: 'Επιλέξτε πελάτη',
  loginToContinue: 'Συνδεθείτε για να συνεχίσετε',
  login: 'Σύνδεση',
  vatNumber: 'ΑΦΜ',
  irsBranch: 'ΔΟΥ',
  county: 'Νομός',
  area: 'Περιοχή',
  basicDetails: 'Βασικά Στοιχεία',
  editAgent: 'Επεξεργασία Υπαλλήλου',
  back: 'Πίσω',
  zipCode: 'Τ.Κ.',
  taxStatus: 'Κατάσταση ΦΠΑ',
  selectTaxStatus: 'Επιλέξτε κατάσταση ΦΠΑ',
  NONE: 'Απαλλασσόμενο',
  FULL: 'Πλήρες',
  PARTIAL: 'Μειωμένο',
  sold: 'Πουλήθηκαν',
  product: 'Προϊόν',
  quantity: 'Ποσότητα',
  tax: 'ΦΠΑ',
  noProductsFound: 'Δεν βρέθηκαν προϊόντα',
  controlPanelAgent: 'Πίνακας Ελέγχου Πωλητή',
  callProgressToday: 'Πρόοδος Κλήσεων Σήμερα',
  completedCourses: 'Ολοκληρωμένες Κλήσεις',
  unansweredCalls: 'Αναπάντητες Κλήσεις',
  doNotDisturb: 'Μην ενοχλείτε',
  numberOfOrdersToday: 'No Παραγγελιών σήμερα',
  subject: 'Θέμα',
  chooseDescription: 'Επιλέξτε περιγραφή',
  WRONG_PRODUCT: 'Λάθος προϊόν',
  DEFECTIVE_PRODUCT: 'Ελαττωματικό προϊόν',
  WRONG_PRICE: 'Λάθος τιμή',
  chooseStatus: 'Επιλέξτε κατάσταση',
  ACTIVE: 'Σε Αναμονή',
  COMPLETED: 'Ολοκληρωμένο',
  choosePriority: 'Επιλέξτε προτεραιότητα',
  LOW: 'Χαμηλή',
  NORMAL: 'Κανονική',
  HIGH: 'Υψηλή',
  chooseAction: 'Επιλέξτε ενέργεια',
  PRODUCT_REPLACEMENT: 'Αντικατάσταση προϊόντος',
  OTHER: 'Άλλο',
  ISSUE_OF_CREDIT: 'Έκδοση πίστωσης',
  COMMUNICATION_FROM_THE_SELLER: 'Επικοινωνία από τον πωλητή',
  COMMUNICATION_FROM_THE_SALES_MANAGER: 'Επικοινωνία από τον διευθυντή πωλήσεων',
  COMMUNICATION_FROM_THE_ACCOUNTING_DEPARTMENT: 'Επικοινωνία από το λογιστήριο',
  COMMUNICATION_FROM_COMMERCIAL_MANAGER: 'Επικοινωνία από τον εμπορικό διευθυντή',
  COMMUNICATION_FROM_CEO: 'Επικοινωνία από τον CEO',
  attachments: 'Συνημμένα',
  complaint: 'Παράπονο',
  'Notes required.': 'Απαιτούνται σημειώσεις.',
  'Action required.': 'Απαιτείται ενέργεια.',
  'Client required.': 'Απαιτείται πελάτης.',
  'Priority required.': 'Απαιτείται προτεραιότητα.',
  'Description required.': 'Απαιτείται περιγραφή.',
  'Order number required.': 'Απαιτείται αριθμός παραγγελίας.',
  'Documentary required.': 'Απαιτείται Παραστατικό.',
  'ShipmentMethod required.': 'Απαιτείται τρόπος αποστολής.',
  'lastName required.': 'Απαιτείται επώνυμο.',
  'firstName required.': 'Απαιτείται όνομα.',
  'Department required.': 'Απαιτείται τμήμα.',
  'Country required.': 'Απαιτείται χώρα.',
  'City required.': 'Απαιτείται πόλη.',
  complaintDescription: 'Περιγραφή Παραπόνου',
  editComplaint: 'Επεξεργασία Παραπόνου',
  deleteComplaintMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το παράπονο;',
  productCode: 'Κωδικός',
  noEntriesFound: 'Δεν βρέθηκαν καταχωρήσεις',
  tasks: 'Εργασίες',
  directorControlPanel: 'Πίνακας Ελέγχου Διευθυντή',
  newTask: '+ Νέα Εργασία',
  allTasks: 'Όλες οι Εργασίες',
  CALL: 'Κλήση',
  MEETING: 'Συνάντηση',
  EMAIL: 'Email',
  addTask: 'Προσθήκη Εργασίας',
  taskName: 'Όνομα Εργασίας',
  taskKind: 'Είδος',
  newTaskModal: 'Νέα Εργασία',
  title: 'Τίτλος',
  addNewTask: 'Προσθήκη Νέας Εργασίας',
  chooseTaskKind: 'Επιλέξτε είδος εργασίας',
  editTask: 'Επεξεργασία Εργασίας',
  deleteTaskMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την εργασία;',
  managerControlPanel: 'Πίνακας Ελέγχου Διαχειριστή',
  noTasksFound: 'Δεν βρέθηκαν εργασίες',
  activeComplaints: 'Ενεργά Παράπονα',
  ordersToday: 'Παραγγελίες Σήμερα',
  managers: 'Διαχειριστές',
  numberOfOrders: 'Αριθμός Παραγγελιών',
  shipment: 'Αποστολή',
  order: 'Παραγγελία',
  fullOrderDate: 'Ημερομηνία Παραγγελίας',
  department: 'Τμήμα',
  clientDetails: 'Στοιχεία Πελάτη',
  shipmentDetails: 'Στοιχεία Αποστολής',
  complaintOrderNumber: 'Αριθμός Παραγγελίας (Προαιρετικό)',
  all: 'Σύνολο',
  valueOfOrdersPerKind: 'Αξίες παραγγελιών ανα είδος',
  numberOfOrdersPerKind: 'Αριθμός παραγγελιών ανα είδος',
  statisticDetails: 'Στατιστικά Στοιχεία',
  frequencyOfOrders: 'Συχνότητα Παραγγελιών',
  orderKinds: 'Είδη παραγγελίας',
  documentary: 'Παραστατικό',
  chooseDocumentary: 'Επιλέξτε Παραστατικό',
  receipt: 'Απόδειξη',
  invoice: 'Τιμολόγιο - ΔΑ',
  chooseShipmentMethod: 'Επιλέξτε τρόπο αποστολής',
  shipmentMethod: 'Τρόπος Αποστολής',
  pickUpFromSupplier: 'Παραλαβή από Προμηθευτή',
  postOffice: 'Ταχυδρομείο',
  courier: 'Courier',
  transportationCompany: 'Μεταφορική Εταιρεία',
  documentaryNumber: 'Αριθμός Παραστατικού',
  firstName: 'Όνομα',
  lastName: 'Επώνυμο',
  saveChanges: 'Αποθήκευση Αλλαγών',
  filters: 'Φίλτρα',
  actions: 'Ενέργειες',
  numberOfOrderByProductType: 'Αριθμός παραγγελιών ανα είδος',
  newPassword: 'Νέος Κωδικός',
  confirmNewPassword: 'Επιβεβαίωση Νέου Κωδικού',
  chooseAgent: 'Επιλέξτε Πωλητή',
  lastContacts: 'Τελευταίες Κλήσεις',
  answered: 'Απάντησε',
  notAnswered: 'Δεν Απάντησε',
  newOrder: 'Νέα Παραγγελία',
  allOrders: 'Όλες οι Παραγγελίες',
  latestOrders: 'Τελευταίες Παραγγελίες',
  callsHistory: 'Ιστορικό Επικοινωνίας',
  totalOrdersAmount: 'Συνολικό Ποσό Παραγγελιών',
  totalOrders: 'Συνολικές Παραγγελίες',
  city: 'Πόλη',
  comments: 'Σχόλια',
  startCall: 'Έναρξη Κλήσης',
  callOutcome: 'Έκβαση Κλήσης',
  save: 'Αποθήκευση',
  country: 'Χώρα',
  chooseCountry: 'Επιλέξτε Χώρα',
  tradeAgreement: 'Εμπορική Συμφωνία',
  deleteTradeAgreementText: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την εμπορική συμφωνία;',
  noTradeAgreementHasBeenSubmitted: 'Δεν έχει υποβληθεί εμπορική συμφωνία.',
  thisCalendarMonth: 'Αυτός ο Μήνας',
  thisCalendarYear: 'Αυτός ο Χρόνος',
  lastCalendarMonth: 'Προηγούμενος Μήνας',
  lastCalendarYear: 'Προηγούμενος Χρόνος',
  yesterday: 'Χθες',
  selectDateRange: 'Επιλέξτε Περίοδο',
  show: 'Εμφάνιση',
  clear: 'Καθαρισμός',
  clientNameOrVat: 'Όνομα Πελάτη ή ΑΦΜ',
  totalOrdersSum: 'Ποσό Παραγγελιών',
  clearFilters: 'Καθαρισμός Φίλτρων',
  selectAll: 'Επιλογή Όλων',
  removeAll: 'Αφαίρεση Όλων',
  newsletters: 'Newsletters',
  newsletterCampaigns: 'Newsletter Καμπάνιες',
  newNewsletter: 'Νέο Newsletter',
  campaignName: 'Όνομα Καμπάνιας',
  emailSubject: 'Θέμα Email',
  newCampaign: 'Νέα Καμπάνια',
  createNewsletter: 'Δημιουργία Newsletter',
  campaignDetails: 'Στοιχεία Καμπάνιας',
  emailDesign: 'Σχεδιασμός Email',
  preview: 'Προεπισκόπηση',
  campaignPreview: 'Προεπισκόπηση Καμπάνιας',
  nextStep: 'Επόμενο Βήμα',
  send: 'Αποστολή',
  chooseList: 'Επιλέξτε Λίστα',
  chooseEmailList: 'Επιλέξτε λίστα Εmail',
  chooseSomeReceivers: 'Επιλέξτε μεμονωμένους παραλήπτες',
  createNewList: 'Δημιουργία νέας λίστας',
  or: 'ή',
  emailSample: 'Δείγμα Email',
  designYourEmail: 'Σχεδιάστε το Email σας',
  emailPreview: 'Προεπισκόπηση Email',
  insertProducts: 'Προσθήκη Προϊόντων',
  attachFiles: 'Επισύναψη Αρχείων',
  passwordChangeWarning: 'Παρακαλώ εισάγετε νέο κωδικό μόνο αν επιθυμείτε να αλλάξετε τον κωδικό σας',
  'Campaign name required.': 'Απαιτείται όνομα καμπάνιας.',
  'Email subject required.': 'Απαιτείται θέμα email.',
  'Mailing list required.': 'Απαιτείται λίστα email.',
  'Mailing list name required.': 'Απαιτείται όνομα λίστας email.',
  emailList: 'Λίστα Email',
  emailGreeting: 'Χαιρετισμός Email',
  productName: 'Όνομα Προϊόντος',
  resetPassword: 'Επαναφορά Κωδικού',
  pleaseTypeEmailtoResetPassword: 'Παρακαλώ πληκτρολογείστε το email σας για να ανακτήσετε τον κωδικό σας',
  clientCallInfo: 'Πληροφορίες για κλήσεις με τον πελάτη',
  orderInfo: 'Πληροφορίες Παραγγελίας',
  emailListName: 'Όνομα Λίστας Email',
  insertList: 'Εισάγετε Λίστα',
  supportedMailingListFiles: '(Υποστηριζόμενος τύπος αρχείου: .xlsx)',
  number: 'Αριθμός',
  selectedClients: 'Επιλεγμένοι Πελάτες',
  close: 'Κλείσιμο',
  noClientsSelected: 'Δεν έχετε επιλέξει πελάτες.',
  fileAttachments: 'Συνημμένα Αρχεία',
  DRAFT: 'Πρόχειρο',
  SENT: 'Απεσταλμένο',
  uploadingFile: 'Ανέβασμα αρχείου...',
  deleteNewsletterMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το newsletter;',
  deleteMailingListMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τη λίστα email;',
  Postponed: 'Αναβολή',
  Completed: 'Ολοκληρώθηκε',
  'No answer': 'Δεν Απάντησε',
  Busy: 'Κατειλημμένο',
  'Wrong number': 'Λάθος αριθμός',
  'New order': 'Νέα παραγγελία',
  'Do not disturb': 'Μην ενοχλείτε',
  newDate: 'Νέα Ημερομηνία',
  backToControlPanel: 'Πίσω στον Πίνακα Ελέγχου',
  creator: 'Συντάκτης',
  noLatestOrdersFound: 'Δεν βρέθηκαν πρόσφατες παραγγελίες.',
  totalOrderValues: 'Συνολικές αξίες παραγγελιών',
  worthOfOrders: 'Αξία Παραγγελιών',
  callStatistics: 'Στατιστικά Κλήσεων',
  fromF: 'από τις',
  callsLeadToOrders: 'κλήσεις οδήγησαν σε παραγγελίες',
  callsWerePostponed: 'κλήσεις αναβλήθηκαν',
  noCallsForToday: 'Δεν υπάρχουν άλλες κλήσεις για σήμερα.',
  noHistoryCallFound: 'Δεν βρέθηκε ιστορικό κλήσεων.',
  noLatestCallsFound: 'Δεν βρέθηκαν πρόσφατες κλήσεις.',
  editList: 'Επεξεργασία λίστας',
  averageOrdersPerMonth: 'Μέσος όρος νέων παραγγελιών ανά μήνα',
  averageOrdersPerYear: 'Μέσος όρος νέων παραγγελιών ανά χρόνο',
  value: 'Αξία',
  today: 'Σήμερα',
  last30Days: 'Τελευταίες 30 ημέρες',
  last365Days: 'Τελευταίες 365 ημέρες',
  Jan: 'Ιαν',
  Feb: 'Φεβ',
  Mar: 'Μαρ',
  Apr: 'Απρ',
  May: 'Μαι',
  Jun: 'Ιουν',
  Jul: 'Ιουλ',
  Aug: 'Αυγ',
  Sep: 'Σεπ',
  Oct: 'Οκτ',
  Nov: 'Νοε',
  Dec: 'Δεκ',
  total: 'Σύνολο',
  completedOrders: 'Ολοκληρωμένες',
  completedCalls: 'Ολοκληρωμένες',
  newOrders: 'Νέες Παραγγελίες',
  busyCalls: 'Κατειλημμένες',
  noAnswerCalls: 'Δεν Απάντησαν',
  postponedCalls: 'Αναβολές',
  year: 'Χρόνος',
  month: 'Μήνας',
  amount: 'Ποσό',
  fullClientName: 'Πλήρες Όνομα Πελάτη',
  phoneNumber: 'Αριθμός Τηλεφώνου',
  callDuration: 'Διάρκεια Κλήσης',
  amountOrDurationOfCallsToday: 'Αριθμός/Διάρκεια Κλήσεων Σήμερα',
  valueAndNumberOfOrders: 'Αξία και Αριθμός παραγγελιών',
  callAttempt: 'Απόπειρα Κλήσης',
  'New number': 'Νέος αριθμός',
  results: 'Αποτελέσματα',
  transportFromEmoria: 'Μεταφορά από Emoria',
  addOptionalIcon: 'Προσθήκη εικόνας (προαιρετικό)',
  pressHereToAddIcon: 'Πατήστε εδώ για να προσθέσετε μια εικόνα στο newsletter.',
  acceptedImage: 'Επιτρεπτοί τύποι αρχείων: jpg, jpeg, png, Μέγιστο μέγεθος αρχείου: 5MB, Διαστάσεις: 1200 X 400 px (ή μεγαλύτερη)',
  myClients: 'Οι πελάτες μου',
  advancedSettings: 'Σύνθετες Επιλογές',
  advancedSettingsNote: 'Παρακαλώ χρησιμοποιήστε τις παρακάτω δυνατότητες μόνο σε εξαιρετικές περιπτώσεις. Το σύστημα μπορεί να μην είναι λειτουργικό μέχρι να ολοκληρωθούν οι εργασίες.',
  estimatedTime: 'Εκτιμώμενος χρόνος',
  sendEmailForGeocode: 'Αποστολή E-mail για Geocode',
  sendEmailForLastDayOrders: 'Αποστολή E-mail για Παραγγελίες προηγούμενης ημέρας',
  sendEmailForOutOfStockProducts: 'Αποστολή E-mail για Προϊόντα Μη Διαθέσιμα',
  clientSync: ' Συγχρονισμός Πελατών & Προϊόντων',
  orderSync: 'Συγχρονισμός Παραγγελιών',
  sync: 'Συγχρονισμός',
  instant: 'Αμέσως',
  about3minutes: 'περίπου 3 λεπτά',
  about30minutes: 'περίπου 30 λεπτά',
  duration: 'Διάρκεια',
  syncWithErp: 'Συγχρονισμός με ERP',
  sendEmail: 'Αποστολή E-mail',
  cancel: 'Ακύρωση',
  systemAlert: 'Προειδοποίηση συστήματος!',
  systemAlertNote1: 'Θέλετε σίγουρα να προβείτε στον επιλεγμένο συγχρονισμό; Η εκτιμώμενη διάρκειά του είναι ',
  systemAlertNote2: ' και η εφαρμογή CRM θα τεθεί εκτός λειτουργίας έως ότου ολοκληρωθεί ο συγχρονισμός.',
  syncInProgressPleaseDontClosePage: 'Ο συγχρονισμός είναι σε εξέλιξη. Παρακαλώ μην κλείσετε τη σελίδα.',
  taskInProgressPleaseWait: 'Η εργασία είναι σε εξέλιξη. Παρακαλώ περιμένετε να ολοκληρωθεί.',
  productGroup: 'Ομάδα Προϊόντων',
  'clientCode required.': 'Απαιτείται Κωδικός Πελάτη.',
  clientCode: 'Κωδικός Πελάτη',
  invoiceCode: 'Αριθμός Παραστατικού',
  invoiceDate: 'Ημερομηνία Παραστατικού',
  deliveryDate: 'Ημερομηνία Παράδοσης',
  paymentStatus: 'Κατάσταση Πληρωμής',
  activateAccount: 'Ενεργοποίηση Λογαριασμού',
  enterPasswordToActivateAccount: 'Εισάγετε ένα κωδικό πρόσβασης για να ενεργοποιήσετε τον λογαριασμό σας.',
  password: 'Κωδικός Πρόσβασης',
  confirmPassword: 'Επιβεβαίωση Κωδικού Πρόσβασης',
  yourAccountIsActivated: 'Ο λογαριασμός σας ενεργοποιήθηκε.',
  enterNewsPassword: 'Εισάγετε νέο κωδικό πρόσβασης.',
  endCall: 'Τερματισμός Κλήσης',
  viewCall: 'Προβολή Κλήσης',
  clientsAgentMap: 'Χάρτης Πελατών',
  choosePeriod: 'Επιλέξτε Περίοδο',
  captchaRequired: 'Απαιτείται Captcha',
  map: 'Χάρτης',
  noCategory: 'Χωρίς Κατηγορία',
  productTypes: 'Είδη Προϊόντων',
  delete: 'Διαγραφή',
  resend: 'Επαναποστολή',
  edit: 'Επεξεργασία',
  noAttachmentsFound: 'Δεν βρέθηκαν συνημμένα',
  files: 'Αρχεία',
  content: 'Περιεχόμενο',
  sendDates: 'Ημ/νίες Αποστολής',
  creationDate: 'Ημ/νία Καταxώρισης',
  totalWorthOfOrders: 'Αξίες Παραγγελιών',
  toughClient: 'Δύσκολος Πελάτης',
  onlyToughClients: 'Μόνο Δύσκολοι Πελάτες',
  yes: 'Ναι',
  no: 'Όχι',
  call: 'Κλήση',
  submit: 'Υποβολή',
  commentForClient: 'Αφήστε ένα σχόλιο για τον πελάτη (μέγιστο 500 χαρακτήρες)',
  'Max 500 characters.': 'Μέγιστο 500 χαρακτήρες.',
  'Comment required.': 'Απαιτείται σχόλιο.',
  'No comments found.': 'Δεν βρέθηκαν σχόλια.',
  'Frequency required.': 'Απαιτείται συχνότητα.',
  callFrequency: 'Συχνότητα Κλήσεων (Σε μέρες)',
  chooseFrequency: 'Επιλέξτε Συχνότητα',
  checkHereForToughClient: 'Επιλέξτε εδώ αν ο πελάτης είναι δύσκολος',
  remainingTotal: 'Συνολικό Υπόλοιπο',
  findocOrder: 'Κωδικός',
  LDEBIT: 'Χρέωση',
  LCREDIT: 'Πίστωση',
  DOCTYPE: 'Τύπος Παραστατικού',
  TRNDATE: 'Ημ/νία Συναλλαγής',
  dateCreatedClient: 'Ημ/νία Ένταξης',
  geoRegion: 'Γεωγραφική Ζώνη',
  notAvailable: 'ΜΗ ΔΙΑΘΕΣΙΜΟ',
  withoutCategory: 'ΧΩΡΙΣ ΚΑΤΗΓΟΡΙΑ',
  a_a: 'Α/Α',
  taxValue: 'Αξία ΦΠΑ',
  code: 'Κωδικός',
  discount: 'Έκπτωση %',
  noTabItems: 'Δε βρέθηκε παραστατικό για την επιλεγμένη λογιστική κίνηση.',
  priceTab: 'Τιμή',
  cash: 'Μετρητοίς',
  cashWithACS: 'Αντικαταβολή ACS',
  mixedModeOfPayment: 'Μικτός τρόπος πληρωμής',
  cashOnDeliveryDeposit: 'Αντικαταβολή Κατάθεση',
  onCredit: 'Επί Πιστώσει',
  credit90days: 'Πίστωση 90 ημερών',
  deposit: 'Κατάθεση',
  cashOnDeliverCheck: 'Αντικαταβολή Επιταγή',
  acs: 'ACS',
  sameMedium: 'Ίδιο Μέσο',
  customer: 'Πελάτης',
  unknown: 'Άγνωστος',
  discount1: 'Έκπτωση 1',
  discount2: 'Έκπτωση 2',
  lot: 'Παρτίδα',
  initialPrice: 'Αρχ. Τιμή/Τμχ',
  finalPrice: 'Τελ. Τιμή/Τμχ',

  // TOAST MESSAGES
  userHasBeenDeleted: 'Ο χρήστης διαγράφηκε επιτυχώς.',
  userHasBeenCreated: 'Ο χρήστης δημιουργήθηκε επιτυχώς.',
  userHasBeenUpdated: 'Ο χρήστης ενημερώθηκε επιτυχώς.',
  yourDetailsHaveBeenUpdated: 'Τα στοιχεία σας ενημερώθηκαν επιτυχώς.',
  offerHasBeenCreated: 'Η προσφορά δημιουργήθηκε επιτυχώς.',
  offerHasBeenUpdated: 'Η προσφορά ενημερώθηκε επιτυχώς.',
  offerHasBeenDeleted: 'Η προσφορά διαγράφηκε επιτυχώς.',
  clientHasBeenCreated: 'Ο πελάτης δημιουργήθηκε επιτυχώς.',
  clientHasBeenUpdated: 'Ο πελάτης ενημερώθηκε επιτυχώς.',
  clientHasBeenDeleted: 'Ο πελάτης διαγράφηκε επιτυχώς.',
  complaintHasBeenCreated: 'Το παράπονο καταχωρήθηκε επιτυχώς.',
  complaintHasBeenUpdated: 'Το παράπονο ενημερώθηκε επιτυχώς.',
  complaintHasBeenDeleted: 'Το παράπονο διαγράφηκε επιτυχώς.',
  taskHasBeenCreated: 'Η εργασία δημιουργήθηκε επιτυχώς.',
  taskHasBeenUpdated: 'Η εργασία ενημερώθηκε επιτυχώς.',
  taskHasBeenDeleted: 'Η εργασία διαγράφηκε επιτυχώς.',
  noChangesMade: 'Δεν έγιναν αλλαγές.',
  orderHasBeenCreated: 'Η παραγγελία καταχωρήθηκε επιτυχώς.',
  selectProductsInTheOrder: 'Επιλέξτε προϊόντα για την παραγγελία.',
  someOfTheSelectedProductsAreOutOfStock: 'Κάποια από τα επιλεγμένα προϊόντα είναι εξαντλημένα.',
  tradeAgreementFileHasBeenRemoved: 'Το αρχείο της εμπορικής συμφωνίας διαγράφηκε επιτυχώς.',
  newsletterHasBeenSend: 'Το newsletter απεστάλη επιτυχώς.',
  newsletterHasBeenSaved: 'Το newsletter αποθηκεύτηκε επιτυχώς.',
  newsletterHasBeenCreated: 'Το newsletter δημιουργήθηκε επιτυχώς.',
  newsletterHasBeenUpdated: 'Το newsletter ενημερώθηκε επιτυχώς.',
  newsletterHasBeenDeleted: 'Το newsletter διαγράφηκε επιτυχώς.',
  mailingListHasBeenCreated: 'Η λίστα email δημιουργήθηκε επιτυχώς.',
  mailingListHasBeenUploaded: 'Η λίστα email ανέβηκε επιτυχώς.',
  mailingListHasBeenUpdated: 'Η λίστα email ενημερώθηκε επιτυχώς.',
  mailingHasBeenDeleted: 'Η λίστα email διαγράφηκε επιτυχώς.',
  selectListToDelete: 'Επιλέξτε λίστα για διαγραφή',
  selectListToEdit: 'Επιλέξτε λίστα για επεξεργασία',
  emailHasBeenSent: 'Το email απεστάλη επιτυχώς.',
  callUpdated: 'Η κλήση ενημερώθηκε επιτυχώς.',
  callNewDate: 'Η κλήση έχει οριστεί για νέα ημερομηνία.',
  selectCallOutcome: 'Επιλέξτε το αποτέλεσμα της κλήσης.',
  brokenGeocodeEmailsHasBeenSent: 'Τα emails με προβληματικές διευθύνσεις απεστάλησαν επιτυχώς.',
  lastDayOrdersEmailHasBeenSent: 'Το email με τις παραγγελίες της προηγούμενης ημέρας απεστάλη επιτυχώς.',
  outOfStockEmailProductsHasBeenSent: 'Το email με τα εξαντλημένα προϊόντα απεστάλη επιτυχώς.',
  emailImageSize5MB: 'Το μέγεθος της εικόνας δεν πρέπει να υπερβαίνει τα 5MB.',
  savePreviousCall: 'Παρακαλώ τερματίστε και αποθηκεύστε την προηγούμενη κλήση πριν ξεκινήσετε νέα.',
  emailWillBeSent: 'Αν διατηρείτε ενεργό λογαριασμό στο σύστημά μας, σύντομα θα λάβετε ένα νέο email ανάκτησης του κωδικού σας.',
  sessionExpired: 'Η σύνδεσή σας έχει λήξει. Παρακαλώ συνδεθείτε ξανά.',
  taskCompleted: 'Η εργασία ολοκληρώθηκε επιτυχώς!',
  syncActionFailed: 'Ο συγχρονισμός δεν ολοκληρώθηκε επιτυχώς.',
  accountActivated: 'Ο λογαριασμός σας ενεργοποιήθηκε επιτυχώς.',
  passwordHasBeenReset: 'Ο κωδικός σας έχει επαναφερθεί επιτυχώς.',
  commentHasBeenCreated: 'Το σχόλιο δημιουργήθηκε επιτυχώς.',
  commentHasBeenDeleted: 'Το σχόλιο διαγράφηκε επιτυχώς.',
  'detail: ActivatePasswordRequest with this code does not exist': 'Ο κωδικός ενεργοποίησης δεν υπάρχει.',
  '0: If you maintain an active account in our system, you will soon receive a new password recovery email.': 'Αν διατηρείτε ενεργό λογαριασμό στο σύστημά μας, σύντομα θα λάβετε ένα νέο email ανάκτησης του κωδικού σας.',
  '0: No account with this email registered!': 'Δεν έχει καταχωρηθεί λογαριασμός με αυτό το email!',
  'detail: No active account found with the given credentials': 'Δεν βρέθηκε ενεργός λογαριασμός με αυτά τα στοιχεία!',
  "0: {'username': ['User with this Username already exists.'], 'email': ['User with this Email already exists.']}": 'Υπάρχει ήδη λογαριασμός με αυτό το όνομα χρήστη ή email!',
  "0: {'email': ['User with this Email already exists.']}": 'Υπάρχει ήδη λογαριασμός με αυτό το email!',
  "0: {'username': ['User with this Username already exists.']}": 'Υπάρχει ήδη λογαριασμός με αυτό το όνομα χρήστη!',
  'name: mailing list with this name already exists.': 'Υπάρχει ήδη λίστα email με αυτό το όνομα.',
  'name: campaign with this name already exists.': 'Υπάρχει ήδη καμπάνια με αυτό το όνομα.',
  'error_messages: planned_date is required': 'Η ημερομηνία προγραμματισμού είναι υποχρεωτική.',
  'detail: Not found.': 'Δεν βρέθηκε.'
}

export default translationEl
