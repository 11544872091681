import { MdTableView } from 'react-icons/md';
import { CSVLink } from 'react-csv';

interface Props {
  usedInTitle?: boolean
  usedInOrder?: boolean
  data: any
}

function CsvButton({ usedInTitle, usedInOrder, data }: Props) {
  return (
    <CSVLink data={data} filename="calls.csv" className={`w-max h-max px-3 py-2 border-solid rounded-[5px] ${usedInTitle ? `py-[11.1px] text-btn-sub text-4xl border-btn-sub ${usedInOrder ? 'mb-0 mt-1' : 'mb-8'} ml-2 border border-t` : 'text-grey-bold text-lg border border-[#BEBEBE]'}`}>
      <MdTableView />
    </CSVLink>
  )
}

export default CsvButton
