import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useGetData from '../../../../../hooks/useGetData'
import apiService from '../../../../../services/api/apiService'
import { ClientLatestCallTypes } from '../../../../../interfaces/client'
import { durationToTime } from '../../../../../helpers/duration-to-time'
import WidgetTitle from '../../../../../components/UI/WidgetTitle'
import LastCallsByDateTable from './LastCallsByDateTable'
import { AgentCallByDate } from '../../../../../interfaces/calls'
import RenderIf from '../../../../../components/UI/RenderIf'
import TableSkeleton from '../../../../../components/Skeletons/TableSkeleton'

interface Props {
  agent: number
}

export default function LastCallsByDate({ agent }: Props) {
  const [t] = useTranslation()
  const [lastCallsDate, setLastCallsDate] = useState<string>('today')

  const { data: calls, isLoading } = useGetData<AgentCallByDate[]>({
    queryKey: `agentCallsByDate ${agent} ${lastCallsDate}`,
    queryFn: () => apiService.getAgentCallByDate({ id: agent, date: lastCallsDate }),
    disabled: !agent
  })

  const onChangeDateHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLastCallsDate(e.target.value)
  }

  return (
    <div className="bg-[#FBFBFB] rounded-[10px] p-[10px] md:py-5 md:px-8">
      <div className="flex items-center justify-between gap-5 flex-wrap">
        <WidgetTitle text="calls" fontWeight="font-semibold" />
        <select
          className="w-full sm:w-max bg-white flex items-center gap-2 border-[1px] border-solid border-[#BEBEBE] rounded-lg text-sm text-grey-pale px-1 h-[46px] outline-none focus:outline-none"
          value={lastCallsDate}
          onChange={onChangeDateHandler}
        >
          <option value="today">{t('today')}</option>
          <option value="yesterday">{t('yesterday')}</option>
          <option value="this month">{t('thisCalendarMonth')}</option>
          <option value="last month">{t('lastCalendarMonth')}</option>
        </select>
      </div>

      <RenderIf isTrue={isLoading}>
        <TableSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isLoading}>
        <LastCallsByDateTable calls={calls} />
      </RenderIf>
    </div>
  )
}
