const headerNames: any = {
  ΑΦΜ: 'vat number',
  'Πλήρες Όνομα': 'full name',
  Κατηγορία: 'category',
  'Τελευταία Επαφή': 'last contact'
}
function sortingHeadersClients(header:string): string | null {
  return headerNames?.[header] ?? null
}

export default sortingHeadersClients
