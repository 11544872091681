const headerNames: any = {
  Αριθμός: 'number',
  Παραστατικό: 'document',
  Πελάτης: 'customer',
  Ημερομηνία: 'date',
  Κατάσταση: 'status',
  Πωλητής: 'seller',
  Πληρωμή: 'payment',
  Αποστολή: 'send to'
}
function sortingHeadersOrders(header:string): string | null {
  return headerNames?.[header] ?? null
}

export default sortingHeadersOrders
