import { registerLocale } from 'react-datepicker'
import { el } from 'date-fns/locale'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import useWidth from '../../hooks/useWidth'
import useGetData from '../../hooks/useGetData'
import apiService from '../../services/api/apiService'
import { TotalProductTypeOrdersStatisticsTypes } from '../../interfaces/statistics'
import ChartHeaderFilters from './ChartHeaderFilters'
import usePredefinedDateRange, { PredefinedDateRangeTypes } from '../../hooks/usePredefinedDateRange'
import RenderIf from '../UI/RenderIf'
import WidgetTitle from '../UI/WidgetTitle'

registerLocale('el', el)

interface Props {
  text: string
  agent?: string
  gridCss?: string
  predefinedDateRange?: PredefinedDateRangeTypes
}

function NumberOfOrdersByProductTypeChart({
  text,
  agent,
  gridCss,
  predefinedDateRange
}: Props) {
  const { t } = useTranslation()
  const localPredefinedDateRange = usePredefinedDateRange()
  const { width } = useWidth()

  const disabled = predefinedDateRange?.predefinedDateRange
    !== predefinedDateRange?.shouldFetchValue
    || !predefinedDateRange?.endDate

  const localDisabled = localPredefinedDateRange.predefinedDateRange
    !== localPredefinedDateRange.shouldFetchValue
    || !localPredefinedDateRange.endDate

  const { data } = useGetData<TotalProductTypeOrdersStatisticsTypes>({
    queryKey: `numberOfOrdersByProductType, ${predefinedDateRange?.predefinedDateRange ?? localPredefinedDateRange.predefinedDateRange} ${agent}`,
    queryFn: () => apiService.getTotalProductTypeOrdersStatistics({
      from: moment(predefinedDateRange?.startDate ?? localPredefinedDateRange.startDate).format('YYYY-MM-DD'),
      to: moment(predefinedDateRange?.endDate ?? localPredefinedDateRange.endDate).format('YYYY-MM-DD'),
      agent
    }),
    disabled: predefinedDateRange?.predefinedDateRange ? disabled : localDisabled
  })
  const print = 'print:w-[800px]'
  return (
    <div className={`${gridCss} bg-[#FBFBFB] rounded-[10px] p-[10px] md:py-5 md:px-8  ${print}`}>
      <RenderIf isTrue={!!predefinedDateRange}>
        <WidgetTitle text={text} fontWeight="font-semibold" />
      </RenderIf>
      <RenderIf isTrue={!predefinedDateRange}>
        <ChartHeaderFilters
          text={text}
          predefinedDateRange={localPredefinedDateRange.predefinedDateRange}
          endDate={localPredefinedDateRange.endDate}
          startDate={localPredefinedDateRange.startDate}
          setPredefinedDateRange={localPredefinedDateRange.setPredefinedDateRange}
          setDateRange={localPredefinedDateRange.setDateRange}
        />
      </RenderIf>
      <div className="h-full w-full mt-10">
        <ResponsiveContainer width="99%" minHeight={400} height={width && width < 640 ? '73%' : '85%'}>
          <BarChart
            data={data?.total ?? []}
            barSize={40}
            margin={{
              bottom: 50
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="orderproduct__product__product_category"
              style={{
                fontSize: '7px'
              }}
              tickMargin={35}
              tickFormatter={(value) => {
                if (value === 'none') {
                  return t('notAvailable')
                }
                if (!value) {
                  return t('withoutCategory')
                }

                return value
              }}
              interval={0}
              angle={-45}
              dx={-36}

            />
            <YAxis
              style={{
                fontSize: '13px'
              }}
              width={50}
              label={{
                value: t('numberOfOrders'),
                angle: 90,
                position: 'insideLeft',
                dy: -70,
                offset: 5,
                fill: '#707070',
                style: {
                  fontSize: '13px'
                }
              }}

            />
            <Tooltip
              labelFormatter={(value) => {
                if (value === 'none') {
                  return t('notAvailable')
                }
                if (!value) {
                  return t('withoutCategory')
                }

                return value
              }}
            />
            <Bar
              dataKey="count"
              fill="#004059"
              name={t('orders')}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default NumberOfOrdersByProductTypeChart
