import { useMemo } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Table from '../../../../../components/Table/Table'
import { ClientOrdersDetails } from '../../../../../interfaces/client'

interface Props {
  clientOrdersDetails?: ClientOrdersDetails[]
}

function ClientOrdersDetailsTable({ clientOrdersDetails = [] }:Props) {
  const { t } = useTranslation()

  const columns = useMemo(() => [
    {
      Header: t('findocOrder'),
      accessor: 'FINDOC'
    },
    {
      Header: t('documentaryNumber'),
      accessor: 'FINCODE'
    },
    {
      Header: t('DOCTYPE'),
      accessor: 'DOCTYPE'
    },
    {
      Header: t('TRNDATE'),
      accessor: 'TRNDATE',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p>
          {moment(value).format('DD/MM/YYYY')}
        </p>
      )
    },
    {
      Header: t('LDEBIT'),
      accessor: 'LDEBIT'
    },
    {
      Header: t('LCREDIT'),
      accessor: 'LCREDIT'
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={clientOrdersDetails}
      storageKey="clientOrdersDetailsTable"
    />
  )
}

export default ClientOrdersDetailsTable
