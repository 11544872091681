import { createContext } from 'react'
import { ModalContextTypes } from './modalsCtx.interface'

const modalsCtx = createContext<ModalContextTypes>({
  showConfirmationModal: false,
  showTaskModal: false,
  showClientsTabModal: false,
  modalText: null,
  resourceId: null,
  isOpen: false,
  selectedTask: undefined,
  setIsOpen: () => {},
  closeTaskModalHandler: () => {},
  closeConfirmationModalHandler: () => null,
  openConfirmationModalHandler: () => null,
  openTaskModalHandler: () => null,
  closeClientsTabModalHandler: () => null,
  openClientsTabModalHandler: () => null
})

export default modalsCtx
